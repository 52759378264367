.add-teacher-button-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}
button.add-teacher-button {
    cursor: pointer;
    height: 40px !important;
    padding: 0 9px 0 15px;
}

.add-coteacher-button-image {
    cursor: pointer;
    color: #523084;
    font-size: 30px !important;
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
    .add-teacher-button-container {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    button.add-teacher-button {
        cursor: pointer;
        margin-top: 20px !important;
        margin-left: 0 !important;
        height: 40px !important;
        padding: 0 9px 0 15px;
        /*width: 100%;*/
    }
}
