#createAccount{
	padding: 40px 20px 20px 20px;
	margin: auto;
	max-width: 600px;
	min-width: 300px;
	color: #000000;
	text-align: center;
}

.logo-teachables-register {
	width: 320px;
	margin-bottom: 10px;
}

.teachable-heading {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.createAccountMainContainer{
    background: #EBEBEB;
    padding: 30px;
    border-radius: 6px;
}
#createAccount h2{
    padding: 0px 0px 20px 0px;
    color: #523084;
}
#createAccount .createAccountFormfields{
	font-size: 14px;
}
#createAccount .createAccountFormfields .firstFormField,
#createAccount .createAccountFormfields .secondFormField
{
	padding: 0px 0px 40px 0px;
}
#createAccount .createAccountFormfields .email-div,
#createAccount .createAccountFormfields .password-div{
	margin: 0px;
}
.requiredField{
	color: #000000;
}
#createAccount .createAccountFormfields label{
	text-align: left;
	width: 100%;
}
#titleDropdown button{
    width: 100%;
}
#createAccount .createAccountFormfields input[type='text'],
#createAccount .createAccountFormfields input[type='number'],
#createAccount .createAccountFormfields input[type='password']
{
	height: 40px;
	padding: 5px;
	font-size: 14px;
	background: #FFFFFF;
	border: 1px solid #CCCCCC;
}
#createAccount .createAccountFormfields input[type='text']:focus,
#createAccount .createAccountFormfields input[type='number']:focus,
#createAccount .createAccountFormfields input[type='password']:focus{
	outline: none;
	background: #FFFFFF;
}
#titleDropdown{
	height: 25px;
	background: #EBEBEB;
	outline: none;
	width: 100%;
}
#titleDropdownList{
    margin-top: 14px !important;
}
#createAccountButtons{
    padding-bottom: 0px !important;
}
#createAccountButtons button{
    width: 140px;
}
#termsDiv{
	width: 100%;
	font-size: 14px;
	text-align: left;
}
.errorMsg.accountForm{
	font-size: 14px;
	display: table;
	padding-top: 10px;
	line-height: 18px;
	text-align: left;
}
.errorMsg.accountForm.password.msg{
	color: #0c84e1;
}
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow:none;
}
#termsDiv label{
	font-family: 'Museo Sans W01_500',arial;
	font-weight: normal;
	cursor: pointer;
	letter-spacing: 0px !important;
	width: 90% !important;
}
#termsDiv a,
#termsDiv a:hover{
	font-size: 14px;
    color: #523084;
    text-decoration: underline;
}
.termsSubDiv{
    margin-top: 10px;
}
.registrationArrow{
	margin-top: 3px;
}

.create-account-sign-in-with-google-classroom-container #signInWithGoogleButton {
	width: 321px !important;
	max-width: 321px !important;
	margin: 0 auto 30px;
}

.create-account-sign-in-with-google-classroom-or-separator {
	font-family: 'Museo Sans W01_700',arial;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 2px;
	margin: 0 0 30px 0;
}

@media (min-width: 510px) {
	.create-account-sign-in-with-google-classroom-container #signInWithGoogleButton {
		width: 400px !important;
		max-width: 400px !important;
		margin: 0 0 30px 0;
	}
}
