.modal-footer.close-no-class-message-button-container {
    text-align: center !important;
    border: none !important;;
    font-weight: 700 !important;;
}
.cancelbtn.close-no-class-message-button {
    margin-right: 0 !important;
}

#uploadedStudentList{
    padding: 20px;
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: 1px;
}

.noClassImportHeader {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;

}

.noClassImportContent {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 30px;
    text-align: center;
}