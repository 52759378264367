#signInContainer {
	margin-top: 37px;
	margin-bottom: 37px;
	padding-left: 0;
	padding-right: 0;
	max-width: 713px;
	min-width: 342px;
	text-align: center;
}
#loginContainer {
	padding: 39px 12px !important;
	background: #EBEBEB;
	font-family: 'Museo Sans W01_500',arial;
	color: #000000;
	text-align: left;
	min-width: 307px;
	border-radius: 5px;
}

.teachable-heading {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.logo-teachables {
	width: 320px;
}

#loginContainer .signInFormFields label {
	display: block;
	padding: 0 5px 0 0 !important;
	font-weight: 500;
	font-size: 20px;
	letter-spacing: 0px;
}

#loginContainer .signInFormFields input {
	width: 100%;
	padding: 5px 10px;
	font-size: 16px;
	background: #FFFFFF;
	border: 1px solid #979797;
	border-radius: 2px;
	margin-bottom: 30px;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

#loginContainer .signInFormFields input:focus {
	background: #FFFFFF;
}

#loginButton {
	width: 245px;
	margin: 0 0 17px 0;
}

.loginPageLinks {
	font-size: 14px;
	padding: 20px 0px 0px 0px;
	letter-spacing: 0;
	text-align: center;
}
.loginPageLinks a,
.loginPageLinks a:hover {
	font-size: 14px;
}
#loginFormContainer {
	padding-left: 0px;
}

.logintopcontainer {
	padding: 5px 0 20px 0;
	font-family: 'Museo Sans W01_300', arial;
	font-size: 26px;
	font-weight: 500;
	color: #64129C;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
	text-align: center;
}

.logintopcontainer h2{
	margin: 0px;
	color: #523084;
}

.logintopcontainer h2 .no-wrap {
	white-space: nowrap;
}

.loginHelpLinks{
	font-size: 14px;
	padding-top: 15px;
	color: #000000;
	letter-spacing: 0;
}
.loginHelpLinks a{
	font-size: 14px;
}
.logintopcontainerText {
	padding-bottom: 20px;
	font-family: 'Museo Sans W01_300', arial;
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
	text-align: center;
	color: #000000;
}
.logintopcontainerText span {
	letter-spacing: 0;
}
.logintopcontainerText a,
.logintopcontainerText a:hover{
	font-size: 18px;
}

.forgot-password-student {
	margin-left: 0px;
	height: 17px;
	font-family: 'Museo Sans W01_500', arial;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
	color: #64129c;
	cursor: pointer;
}

.forgot-password-student:hover,
.forgot-password-student:focus {
	color: #3e077b;
	text-decoration: underline;
}

#forgotAccInfoLink{
	margin-left: 0px;
	width: 150px;
	height: 17px;
	font-family: 'Museo Sans W01_500', arial;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
	text-align: center;
	color: #64129c;
}
#forgetPasswordLink{
	text-align: right;
	letter-spacing: 0;
}
#forgotAccInfoLink:hover,
#forgotAccInfoLink:focus{
	color: #64129c;
}
#loginPasswordInput{
	margin-bottom: 5px !important;
}
#loginButtonDiv{
	padding-top: 20px;
}
#registerAccountLink{
	margin: 0px 5px 10px 0px;
}
.loginCleverLinks{
	margin-bottom: 10px;
}
.logInGlyph,.registerGlyph{
	padding-right: 10px;
}
#registerAccountBtn{
	width: 140px;
}
.loginPageBtns{
	float: right;
	display: table-cell;
	vertical-align: middle;
}
.loginPageLinksSubDivText{
	padding: 0px 0px 10px 0px;
}
#loginButtonDiv {
	text-align: center;
}
#registerAccountDiv{
	background: #FFFFFF;
}
#googleLoginButtonDiv {
	padding-top: 15px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 700;
	/*color: #000000;*/
 }
#loginContainer .otherSignInMethods label {
	margin-top: 16px;
	margin-bottom: 52px;
	padding: 16px;
	font-weight: 500;
	font-size: 20px;
	letter-spacing: 0;
}
.signInPanelsContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.signInPanelLeft {
	width: 100%;
}
.horizontalStyleLine {
	display: block;
	margin-top: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #979797; /* #9A9A9A; */
}
.verticalStyleLine {
	display: none;
	border-left: 1px solid #979797; /* #9A9A9A; */
}
.signInPanelRight {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.otherSignInMethods {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.signInButtonsContainer {
	text-align: center;
}
.otherSignInButton {
	text-decoration: none;
	display: block;
	width: 245px;
	height: 55px;
	margin: 0 auto;
	/*margin: 0 0 52px 7px;*/
	padding: 11px 19px;
	outline: none;
	border: none;
	border-radius: 28px;
	background: #FFFFFF;
	color: #000000;
	font-size: 16px;
	font-family: 'Google Sans',arial;
	font-weight: 500;
	letter-spacing: 0;
}
.otherSignInButton:not(:last-child) {
	margin-bottom: 52px;
}
.otherSignInButton:hover {
	text-decoration: none !important;
	cursor:pointer;
	color: #000;
}
.otherSignInButton:focus,
#loginUserNameInput:focus,
#loginPasswordInput:focus,
#loginButton:focus {
	text-decoration: none;
	box-shadow: 0 0 0 2px blue;
}
#signInWithGoogleButton img {
	margin-right: 12px;
}
#cLogoSvg {
	width: 106px;
	height: 29px;
}
#gLogoSvg {
	width: 34px;
	height: 34px;
}
#mLogoSvg {
	margin-right: 12px;
	width: 34px;
	height: 34px;
	border-radius: 50%;
}
#loginButton {
	height: 55px;
	border-radius: 28px;
	font-size: 18px;
	width: 245px !important;
	background-color: #4e0a7e;
}
#googleLoginButtonLight {
	text-align: center;
}
.sdm-links {
	font-family: 'Museo Sans W01_300', arial;
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
	text-align: center;
	text-decoration: none;
	color: #680fa6;
}
.sdm-links:hover,
.sdm-links:focus {
	color: #3e077b;
	text-decoration: underline;
}
.yourScholasticResourcesBR {
	display: none;
}
.registerForFree {
	display: block;
}
.loginHelpLinks {
	display: inline-block;
	padding: 47px 0 0 0;
}
.needHelpBR {
	display: block;
}
.visitForHelp {
	display: inline-block;
}
.visitForHelpBR {
	display: block;
}
.orContactUs {
	display: inline-block;
}
.orContactUsBR {
	display: block;
}
.schNumber {
	display: inline-block;
}
.museoSans300 {
	font-family: 'Museo Sans W01_300', arial;
	font-size: 18px !important;
	font-weight: 300 !important;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
}
.museoSans500 {
	font-family: 'Museo Sans W01_500', arial;
	font-size: 18px !important;
	font-weight: 500 !important;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0;
}
@media (min-width: 480px) {
	#signInContainer {
		padding-left: 20px;
		padding-right: 20px;
	}
	#loginButton {
		width: 245px !important;
	}
}
@media (min-width: 481px) {
	.needHelpBR {
		display: none;
	}
	.orContactUsBR {
		display: none;
	}
}
@media (min-width: 613px) {
	#signInContainer {
		padding-left: 20px;
		padding-right: 20px;
	}
	.signInPanelsContainer {
		flex-direction: column;
	}
	.signInPanelLeft {
		width: 100%;
	}
	#loginButtonDiv {
		text-align: center;
	}
	#loginButton {
		width: 245px !important;
		font-size: 18px;
	}
	.signInPanelRight {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.verticalStyleLine {
		display: none;
	}
	.horizontalStyleLine {
		display: block;
	}
	.otherSignInMethods {
		width: 100%;
		/*min-height: 225px;*/
		padding-left: 0;
		margin-left: 0;
	}
	.otherSignInButton {
		max-width: 245px !important;
		width: 100%;
		text-align: center;
	}
	#loginWithCleverLink {
		width: 260px !important;
		max-width: 260px !important;
	}
	#loginWithMyScholasticLink {
		width: 260px !important;
		max-width: 260px !important;
	}
	#signInWithGoogleButton {
		width: 260px !important;
		max-width: 260px !important;
	}
	.registerForFree {
		display: inline-block;
	}
	.visitForHelpBR {
		display: none;
	}
	.orContactUsBR {
		display: block;
	}
}
@media (min-width: 768px) {
	#signInContainer {
		padding-left: 0;
		padding-right: 0;
	}
	.yourScholasticResourcesBR {
		display: block;
	}
	#loginContainer {
		padding: 40px 22.5px 40px 22.5px !important;
		border-radius: 5px;
		background-color: #ebebeb;
	}
	#loginContainer form {
		height: 100%;
	}
	#loginButtonDiv {
		text-align: center;
	}
	#loginButton {
		width: 183px !important;
		margin-right: 50px;
	}
	.signInFormFields label{
		display: block;
		max-width: 280px;
		width: 85%;
	}
	#loginContainer .signInFormFields input {
		display: block;
		max-width: 312px;
		height: 47px;
		width: 100% !important;
		margin-bottom: 30px !important;
	}
	#loginContainer .signInFormFields #loginPasswordInput {
		margin-bottom: 5px !important;
	}
	#forgetPasswordLink {
		display: block;
		margin-right: 23px;
		margin-bottom: 35px;
	}
	.studentPasswordLinkHeight {
		margin-right: 23px;
		margin-bottom: 35px;
	}
	.signInPanelsContainer {
		height: 100%;
		display: flex;
		flex-direction: row;
	}
	.signInPanelLeft {
		margin-top: 10px;
		width: 50%;
	}
	.verticalStyleLine {
		display: block;
	}
	.horizontalStyleLine {
		display: none;
	}
	.signInPanelRight {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 45px;
	}
	.otherSignInMethods {
		justify-content: unset;
	}
	#loginContainer .otherSignInMethods label {
		margin: 0 0 13px 0;
		padding: 0 0 0 0;
	}
	#loginWithCleverLink {
		width: 260px !important;
	}
	#loginWithMyScholasticLink {
		width: 260px !important;
	}
	.signInButtonsContainer {
		display: block;
		text-align: center;
	}
}
@media (min-width: 992px){
	#signInContainer {
		padding-left: 0;
		padding-right: 0;
		max-width: 880px !important;
		width: 794px;
	}
	#loginContainer .signInFormFields input {
		max-width: 350px;
	}
	#loginButtonDiv {
		text-align: center;
	}
	#loginButton {
		width: 183px !important;
	}
	.otherSignInMethods {
		justify-content: unset;
	}
	#loginContainer .otherSignInMethods label {
		margin: 0 0 13px 0;
		padding: 0 0 0 0;
	}
}
@media (min-width: 1200px){
	#signInContainer{
		padding-left: 0;
		padding-right: 0;
		max-width: 880px !important;
		width: 794px;
	}
	#loginContainer .signInFormFields input {
		max-width: 350px;
	}
	#loginButtonDiv {
		text-align: center;
	}
	#loginButton {
		width: 183px !important;
	}
	.otherSignInMethods {
		justify-content: unset;
	}
	#loginContainer .otherSignInMethods label {
		margin: 0 0 13px 0;
		padding: 0 0 0 0;
	}
}

.modal-my-scholastic {
	width: 100%;
	height: 100%;
}

.my-scholastic-close-btn {
	display: block;
	margin-left: auto;
}

.modal-my-scholastic .modal-content {
	border-radius: 0 !important;
}

.modal-my-scholastic .modal-content,
.modal-my-scholastic .my-scholastic-modal,
.modal-my-scholastic .my-scholastic-modal iframe {
	border: none;
	width: 100%;
	height: 100%;
}

@media (max-width: 1045px){
	.modal-my-scholastic {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-width: 1046px){
	.modal-my-scholastic {
		width: 1024px;
		height: 709px;
	}

	.my-scholastic-close-btn {
		display: none;
	}
}

