[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.subHomeContainer {
	margin: 20px 0px 40px 0px;
	clear: both;
}

.logo {
	width: 12%;
	background-color: #ed0707;
	text-align: center;
}

.home-warning-alert {
	border: 2px solid #f5a623;
	border-radius: 6px;
	background: #fff;
	margin: 15px 15px 10px 15px;
	padding: 0;
	word-break: break-word;
}

.home-alert-warning-div {
	background: #f5a623;
	color: #fff;
	display: table-cell;
	padding: 10px;
	vertical-align: middle;
}

.blue-banner-star {
	border: 2px solid #4c357f;
	border-radius: 6px;
	background: #fff;
	margin: 15px 15px 10px 15px;
	padding: 0;
	word-break: break-word;
}

.alert-blue-star-div {
	background: #4c357f;
	color: #fff;
	display: table-cell;
	padding: 10px;
	vertical-align: middle;
}

.alert-blue-text-div{
	display: table-cell;
	padding: 10px;
	vertical-align: middle;
	color:black;
	font-family: 'Museo Sans W01_500', arial;
	font-weight: normal;
	font-size: 16px;
}

.link-learn-more {
	cursor: pointer;
	color: #3d98f5;
}

input[type='text'],
input[type='number'],
input[type='password']{
    border-radius: 2px;
    border: 1px solid #CCCCCC;
    height: 40px;
    outline: none;
}

label{
    font-weight: bold;
	font-size: 14px;
	letter-spacing: 2px;
}

a{
	outline: none;
}

a:focus{
	outline: none;
}

a:hover{
	cursor: pointer !important;
}

button{
	outline: none;
}

body{
    background-color: #FFFFFF;
}

h2{
	font-family: 'Museo Sans W01_500',arial;
    font-size: 26px;
    margin: 0px;
}

h4{
    font-family: 'Museo Sans W01_500',arial;
}
.center{
    text-align: center;
}
.left{
    text-align: left;
}
#maincontainer{
	padding: 0px;
	min-height: 490px;
	background: #FFFFFF;
	font-family: 'Museo Sans W01_500',arial;
}

.addclass{
	width:450px;
	margin: auto;
	border: 1px solid #b3b3b3;
	font-family: 'Museo Sans W01_500',arial;
	padding-bottom: 20px;
}
.addclass .cheader{background-color:#b3b3b3;}
.addclass .cheader div{display:inline-block;}
.addclass .cheader h4{margin:10px;}
.addclass .close{padding:10px; color:#111111;}
.addclass .close a{cursor:pointer; text-decoration:none; color:#111111;}
.addclass .contentcontainer{padding-left:40px; padding-top:10px;}
.addclass  #inputName {width: 370px; height:35px; padding:0 0 0 5px;}
.addclass .classtitle,.addclass .classgrade {font-weight:bold; font-size:14px;}
.addclass .classinput{margin:10px 0;}
.addclass .gradebox{margin:8px;}
.addclass .footerform {margin: 20px 0 0 0;}


.pagecontainer{/* width:930px; */ margin:auto; background-color:#FFFFFF; font-family: 'Museo Sans W01_500',arial;  padding-bottom: 1px;}
.pagecontainer #topnav{
    width:100%;
    height: 140px;
    background: url(../images/header.jpg) repeat-x;
    color:#fff;
    font-size:11px;
    height: 60px;
}
.pagecontainer #navigation ul{display:inline-block; padding: 28px 0px 0px 0px; /* margin-top: 15px; */}
.pagecontainer #topnav div { display:inline-block; }
.pagecontainer  .menu{padding-left:5px;}
.pagecontainer  .accinfo {float: right; font-size: 14px; color:#FFFFFF;}
.pagecontainer #accButton{
    background: none;
    border: none;
    outline: none;
    color: #FFFFFF;
    letter-spacing: 2px;
    padding: 0px;
}

.user-dropdown{
	border-radius: 0px;
	background: #ffffff;
	color: #337ab7;
	padding: 0px;
	right: 4px;
	left: auto;
}
.user-dropdown li:hover{
	cursor: pointer;
}
.user-dropdown li:nth-last-of-type(1){
    border-bottom: none;
}
.user-dropdown li{
    border-bottom: 1px solid #CCCCCC;
}
.user-dropdown li > a{
	display: inline-block;
	padding: 10px 8px;
	width: 100%;
	color: #337ab7;
}
.user-dropdown li > a:hover{
	background: #FFFFFF;
	color: #337ab7;
}
.user-dropdown-glyphs{
	padding-right: 5px;
}

.pagecontainer #navigation ul li{
	display:inline-block;
	width:170px;
	text-align:center;
	font-size:16px;
	font-weight:bold;
	margin-left: 30px;
	border-radius: 10px 10px 0px 0px;
	background: #007ADE;
    border: 1px solid #fff;
}

.pagecontainer #navigation ul li.active{ background-color: #FFFFFF; }
.pagecontainer #navigation ul li.active a{ color: #523084; }
.pagecontainer #navigation ul li a{color:#FFFFFF; text-decoration:none;outline: none; font-family: 'Museo Sans W01_500',arial; letter-spacing: 2px; display: inline-block; width: 100%; padding: 10px 0px;}
.pagecontainer .selected{background-color:#d1e6ff;}
.classhome{width:900px; margin:auto;}
.classhome h3{text-align:center;}
.classhome .createbtn{margin-left:360px;}

#userInfo,#loginFromHelpPage{
	float: right;
	padding: 20px 10px;
	font-size: 14px;
}

#loginFromHelpPage a{
	display: inline-block;
	color: #FFFFFF;
	padding-right: 10px;
}

#userName{
	letter-spacing: 2px;
	font-family: 'Museo Sans W01_500',arial;
	font-weight: bold;
}

#logout,#changePasswordLink{
	padding-left: 15px;
}

#logout a,#changePasswordLink a{
	font-family: 'Museo Sans W01_500',arial;
	text-decoration: underline;
	outline: none;
	color: #ffffff;
}

.loadingMessage {
	font-size: 20px;
	margin-top: 20px;
	color: red;
}

#addedClass{
	width: 450px;
	min-height: 100px;
	border: 1px solid #333333;
	font-family: 'Museo Sans W01_500',arial;
	margin: auto;
	padding-left: 20px;
}
.errorMsg{
	color: #EF7000;
	font-size: 14px;
}
#loginError{
	font-size: 18px;
	display: block;
	text-align: left;
}
.easyloginText{
	font-size: 10px;
	display: block;
}
.easylogin{
	padding-top: 10px;
}
.toggle{
	background: url(../images/toggleOff.jpg) no-repeat;
	display: inline-block;
	height: 30px;
	width: 50px;
	border: none;
	outline: none;
}
.toggle.true {
	background: url(../images/toggleOn.jpg) no-repeat;
}
.easyloginbtntxt{
	color: #777777;
	font-size: 14px;
	font-weight: 800;
	vertical-align: super;
}
.easyloginbuttondiv{
	padding-top: 10px;
}

.test {
	width: 450px;
	background-color: #fff;
	margin: auto;
	margin-bottom:10px;
	height: 65px;
	border: 1px solid #b3b3b3;
	padding: 13px 0 0 10px;
}

#navigation {
	background: #007ADF url(../images/ScholasticDigitalManager-Background.png) no-repeat;
	background-position: right center;
	background-size: 930px;
	height: 150px;
	overflow: hidden;
}
#navigation h1{
	margin: 0px;
	color: #ffffff;
	font-family: 'Museo Sans W01_500',arial;
	font-weight: bold;
	font-size: 32px;
	letter-spacing: 3px;
	padding: 58px 0px 0px 30px;
}

.plusright{
	float:right;
	font-size: 18px;
	top: -12px! important;
	right: 10px;
	color:#111111;
}

.plusright:hover{
	cursor: pointer;
}

/* used for a print icon which will be listed next to the plus icon on the class creation page */
.printright{
	padding-right:10px;
}


.showdiv{display:block! important;}
.cname{width:410px;}

::-webkit-input-placeholder { font-weight: 100; color: #BBBBBB;   }
::-moz-placeholder { font-weight: 100; color: #BBBBBB;   }
:-ms-input-placeholder { font-weight: 100; color: #BBBBBB;  }
input:-moz-placeholder { font-weight: 100; color: #BBBBBB; }

#unauthorized{text-align:center;}

#singleSelect {width: 370px;height: 35px;}

#schoolName
{
	width: 370px;
	height: 35px;
	padding: 0 0 0 5px;
	color: #333333;
}

#validOrgsError{
	font-size: 24px;
	padding: 10px;
	margin: 10px;
	color: red;
	text-align: center;
}

/*
Styles used for alternating rows in student list table
colors pulled out of wireframe PDF
*/
#studentListTable {
	background-color : #ffffff;
	margin: 0px auto;
}

#studentListTable .tdWidth {
	width:20%;
}
#studentListTable .studentList{
	background-color : #ffffff;
}

#studentListTable .rollOverText{
	display: none;
}
#studentListTable tr:hover .rollOverText{
	display: block;
}

#studentListTable .rollOffText{
	display: block;
}
#studentListTable tr:hover .rollOffText{
	display: none;
}
#studentListTable .studentListAlt{
	background-color: #EBEBEB;
}
.studentinfo:hover{
	cursor: pointer;
}
#studentListTable th{
	border-bottom: 1px solid #CCCCCC;
	padding-bottom: 5px;
}
#studentListTable td{
	height: 40px;
	word-break: break-word;
	text-align: left;
}

#studentListTable td.name{
	padding-right: 10px;
}
.grayout td{
	color: #B7B7B7 !important;
}

.grayout{
	opacity: 0.6;
	filter: alpha(opacity = 60 );
}

/* MB: Show pointer while hovering on the table rows */
#studentListTable tr:hover{
	cursor: pointer;
}
/*
	CSS for Class List dropdown
 */

 #classListDropdown{
 	border: none;
 	margin: auto;
 	background-color: #f5f5f5;
 	outline: none;
 	display: block;
 	font-size: 24px;
 	-webkit-appearance: none;
 	-moz-appearance: none;
 	appearance: none;
 	background:  url(../images/dropdown-arrow.png) no-repeat;
 	background-position: center right;
 	padding-right: 20px;
 	-webkit-padding-start: 50px;
 	padding-left: 50px\0;
 	text-align: center;
 	-moz-outline: none;
 }

#classListDropdown:hover{
	cursor: pointer;
}

#classListDropdown::-ms-expand {
        display: none;
}

select::-ms-value {
	background: #fff;
	color: #000000;
}

option[disabled],option:disabled{
	background: #fff;
}

#classListDropdown::-ms-value {
	background: #f5f5f5;
	color: #000000;
}

#studentTableDiv{
	padding: 40px 20px 20px 60px;
}

#studentInfoHeader{
	font-size: 22px;
	font-weight: 600;
}

#nonClevernoStudents h1{
	margin: 10px 0px 15px 5px;
	font-size: 24px;
	font-weight: bold;
	color: #523084;
	text-align: left;
}

#nonClevernoStudents .buttonRow{
	clear:both;
	text-align: center;
	padding: 15px 0px;
}

#addStudentConfirm{
	padding: 10px 50px;
	margin: 10px 0px 0px 5px;
}


#userActivatedMsg{
	color: red;
}

.rollOverToggle:hover .hoverText, .rollOverToggle .nonHoverText {
	display: block;
}

.hoverText, .rollOverToggle:hover .nonHoverText {
	display: none;
}
.stdbtns{
	outline: none;
	border: none;
	width: auto;
	background: #523084;
	color: #FFFFFF;
	border-radius: 22px;
	font-size: 11px;
	font-family: 'Museo Sans W01_500',arial;
	font-weight: bold;
    padding: 6px 30px;
    height: 40px;
    letter-spacing: 2px;
}

.cancelbtn{
	background: #FFFFFF;
	color: #523084;
	border: 1px solid #523084;
	margin-right: 20px;
	padding: 5px 30px;
}

.cancelbtn:hover,
.cancelbtn:focus{
	background: #FFFFFF !important;
	color: #523084 !important;
	border: 1px solid #523084;
}

.stdbtns:hover,
.stdbtns:focus
{
    background: #523084;
    color: #FFFFFF;
}

.inactivebtn{
	opacity: 0.5;
	outline: none;
	width: auto;
	border-radius: 22px;
	font-size: 11px;
	font-family: 'Museo Sans W01_500',arial;
	font-weight: bold;
	height: 40px;
	letter-spacing: 2px;
    background: darkgrey;
    color: grey;
    border: 1px solid grey;
    padding: 5px 30px;
}

.inactivebtn:hover,
.inactivebtn:focus{
	background: darkgrey;
	color: grey;
	border: 1px solid grey;
	-webkit-box-shadow: none !important;
	box-shadow: none  !important;
}

.redBorderBtn{
    background: #FFF;
    color: #EC1D25;
    border: 1px solid #EC1D25;
    padding: 5px 30px;
}

.redBorderBtn:hover,
.redBorderBtn:focus{
    background: #FFF;
    color: #EC1D25;
    border: 1px solid #EC1D25;
}

.stdPlus{
	padding-right: 5px;
	font-size: 16px;
	vertical-align: bottom;
}

@-moz-document url-prefix() {
   .stdPlus{
	vertical-align: bottom;
}
}

#qefEdit,#qefSave{
	width: 130px;
}

#qefEdit{
	margin-right: 20px;
}

#nonClevernoStudents{
	padding: 15px;
	background: #FFFFFF;
}

.inEditMode{
	padding-right: 15px !important;
}
 @media all and (-ms-high-contrast:none)
     {

    .inEditMode{
       padding-right: 15px !important;
        }
     /* IE10 */
     *::-ms-backdrop, #nonClevernoStudents{
       padding-right: 390px  /* IE11 */
        }
     .noStudentsWithGrade{
       padding-right: 210px !important;
    }
    .inEditMode{
       padding-right: 15px !important;
    }

 }

.dropdown-generic{
	display: inline-block;
}

.gradeDropdown{
	background: #FFFFFF;
    padding: 0px;
    margin: 9px 0px !important;
    width: 100%;
    box-shadow: none;
}

.gradeDropdown li{
	background: #FFFFFF;
	color: #FFFFFF;
	border: 1px solid #CCC;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}

.gradeDropdown li a{
	padding: 10px 5px;
	font-family: 'Museo Sans W01_500',arial;
	letter-spacing: 0.5px;
	color: #000000;
	word-wrap: break-word;
	white-space: normal;
}

.gradeDropdown li a:hover{
	outline: none;
	color: #fff;
	background: #523084;

}

.gradeDropdown li a:focus{
	background: #523084;
	color:#fff;
	outline: none;
}

.dropdownOptions-noMargin{
    margin: 0px !important;
}

#csvImportLink{
	font-size: 14px;
	margin-top: 15px;
	clear:both;
	text-align: left;
}

#csvImportLink a,
#csvImportLink a:hover,
#csvImportLink a:focus{
    text-decoration: none;
    color: #523084;
}
.productHome{
    color: #4A494A;
}
.mainSubContainer{
    margin: 20px 40px 40px 40px;
}

.productHome h3{
    padding: 0px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: 1px;
    color: #523084;
    margin: 0px;
}
.productHome .contentcontainer h1{
	text-align: left;
	font-size: 16px;
	font-weight: bold;
	font-family: 'Museo Sans W01_500',arial;
	padding: 20px 0px;
	color: #523084;
	margin: 0px;
}

#addProductAccessCodeText{
	font-size: 14px;
	font-family: 'Museo Sans W01_500',arial;
	letter-spacing: 1px;
	color: #000000;
}

.productHome .noProductText {
	text-align: center;
	font-size: 18px;
	font-weight: 100;
	padding-left: 40px;
	padding-top: 10px;
}

/* inspired by the .test class which is used on the class page */
.productHome .productBox {
	width: 450px;
	background-color: #fff;
	margin: auto;
	margin-bottom:10px;
	border: 1px solid #b3b3b3;
	padding: 13px 0 13px 10px;
	text-align: left;
}


.productHome .plusright{
	float:right;
	font-size: 18px;
	top: -40px! important;
	right: 10px;
	color:#111111  ;
}

.productHome .onlyProductName{
	top: -30px !important;
}

.applicationIcon.teacherProductIcon{
	padding: 10px;
	margin: 20px;
	text-align: center;
	border: 1px solid #CCCCCC;
	display: inline-block;
}
#applicationNameTeacher{
	padding-top: 10px;
	font-weight: bold;
}

#teacherProductLaunch{
	padding: 10px;
}

.applicationIcon {
	padding-left: 10px;
	cursor: pointer;
}
.applicationIcon.studentAppIcon{
	padding: 15px;
	margin: 20px 20px 20px 0px;
	text-align: center;
	display: inline-block;
	border: 1px solid #CCCCCC;
}

#studentWelcomeText{
	text-align: center;
	padding: 30px;
}

#studentWelcomeText h1{
	font-size: 34px;
	font-weight: 100;
	margin: 0px;
}

#studentWelcomeText h3{
	margin: 10px;
}

#studentProductLaunch{
	padding: 10px;
}

.schoolDropdownDiv{
    padding-top: 10px;
}

.studentOrgDropdownBtn{
    width: 100%;
}

.studentOrgDropdownOptions{
    letter-spacing: 1px;
    width: 100%!important;
    margin: 0!important;
}

#applicationNameStudent{
	padding-top: 10px;
    font-family: 'Museo Sans W01_700',arial;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000000;
}

#studentsAddedMsg{
	color: red;
	font-size: 18px;
	padding-bottom: 10px;
    display: block;
}

.onlyPrint {
	display: none;
}

.dropdown-item{
	display: block;
	text-decoration: none;
	outline: none;
	color: black;
}

.dropdown-item:hover{
	text-decoration: none;

}

.dropdownGeneric, .dropdownError, .dropdownSuccess{
	font-family: 'Museo Sans W01_500',arial;
	color: #000000;
	border: 1px solid #CCC;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0.5px;
	text-align: left;
	white-space: normal;
	word-wrap: break-word;
	border-radius: 7px;
	float: none !important;
	background: #FFFFFF;
	min-height: 40px;
}

.dropdown-item-disabled{
	background : #cccccc;
}


.dropdownGeneric:hover, .dropdownError:hover, .dropdownSuccess:hover{
	color: #000000;
	border: 1px solid #CCC;
	background: #FFFFFF;
}

/* make drop down error in the pink color used for other errors */
.dropdownError{
	border: 1px solid  #EC1D25 !important;
    color:  #EC1D25 !important;
}

.dropdownError:hover{
	border: 1px solid  #EC1D25 !important;
    color:  #EC1D25 !important;
}

.dropdownSuccess{
	color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}

.dropdownSuccess:hover{
	color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}

.dropdownOptionsGeneric{
	padding: 0px;
	margin: 0px;
	border: none;
}

.dropdownOptionsGeneric a{
	color:#4A4A4A;
	font-size: 14px;
	font-weight: normal;
	padding: 5px;
	background: #EBEBEB;
	border: 1px solid #CCC;
}

.dropdownOptionsGeneric a:hover{
	background: #523084;
	color: #fff;
	text-decoration: none;
	outline: none;
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.classdropdownarrow{
 	float: right;
}

.createclass{
	padding-right: 5px;
}

/* ---  Media Queries --- */

/* Large desktops and laptops */
@media (min-width: 1200px) {
    #registerAccountLink{
        width: 49%;
    }
    #loginAsStudentLink{
        width: 49%;
    }
    #loginContainer .signInFormFields input{
        width: 73%;
    }
    #loginContainer .signInFormFields label{
        width: 25%
    }
    #productsOrgTypeAhead{
        width: 25%;
    }
    #subscriptionSchoolTypeAhead{
        width: 55%;
    }
    #createAccount .createAccountFormfields input[type='text'],
    #createAccount .createAccountFormfields input[type='number'],
    #createAccount .createAccountFormfields input[type='password']{
	   width: 100%;
    }
    .updatePasswordClassSubHeader{
        width: 45%;
    }
    .changePasswordInputDiv{
        width: 52%;
    }
    .passwordInputBox{
        width: 100%;
    }
    .easyloginshapes-scrollable-div{
        max-height: 400px;
    }
    .studentOrgDropdown{
        width: 25%;
    }
    .el-pref-settings-card {
        padding: 10px !important;
        max-width: 290px !important;
    }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    #registerAccountLink{
        width: 49%;
    }
    #loginAsStudentLink{
        width: 49%;
    }
    #loginContainer .signInFormFields input{
        width: 73%;
    }
    #loginContainer .signInFormFields label{
        width: 25%
    }
    #productsOrgTypeAhead{
        width: 40%;
    }
    #subscriptionSchoolTypeAhead{
        width: 60%;
    }
    #createAccount .createAccountFormfields input[type='text'],
    #createAccount .createAccountFormfields input[type='number'],
    #createAccount .createAccountFormfields input[type='password']{
       width: 100%;
    }
    .updatePasswordClassSubHeader{
        width: 45%;
    }
    .changePasswordInputDiv{
        width: 52%;
    }
    .passwordInputBox{
        width: 100%;
    }
    /*.tooltip{*/
        /*display: none !important;*/
    /*}*/
    .easyloginshapes-scrollable-div{
        max-height: 400px;
    }
    .studentOrgDropdown{
        width: 50%;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    #registerAccountLink{
        width: 49%;
    }
    #loginAsStudentLink{
        width: 49%;
    }
    #loginContainer .signInFormFields input{
        width: 73%;
    }
    #loginContainer .signInFormFields label{
        width: 25%
    }
    #addProductAccessCodeText{
        display: block;
    }
    #productsOrgTypeAhead{
        width: 50%;
    }
    #subscriptionSchoolTypeAhead{
        width: 99%;
    }
    .loaderGIF{
        left: 42% !important;
        top: 35% !important;
    }
    #createAccount .createAccountFormfields input[type='text'],
    #createAccount .createAccountFormfields input[type='number'],
    #createAccount .createAccountFormfields input[type='password']{
       width: 100%;
    }
    .updatePasswordClassSubHeader{
        width: 45%;
    }
    .changePasswordInputDiv{
        width: 52%;
    }
    .passwordInputBox{
        width: 100%;
    }
    .pagecontainer #navigation ul li{
        margin-left: 15px !important;
        width: 160px !important;
    }
    .easyloginshapes-scrollable-div{
        max-height: 400px;
    }
    .accesscodeInfoText{
        margin: 20px 0px 0px 0px !important;
    }
     .studentOrgDropdown{
        width: 50%;
    }
    .easy-login-grey-background{
        border-radius: 0px !important;
        width: 100%;
    }
    .easy-login-grey-border{
        border-radius: 0px !important;
        width: 100%;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    #registerAccountLink{
        width: 49%;
    }
    #loginAsStudentLink{
        width: 49%;
    }
    #loginContainer .signInFormFields input{
        width: 73%;
    }
    #loginContainer .signInFormFields label{
        width: 25%
    }
    #addProductAccessCodeText{
        display: block;
    }
    #productsOrgTypeAhead{
        width: 50%;
    }
    #subscriptionSchoolTypeAhead{
        width: 50%;
    }
    .loaderGIF{
        left: 42% !important;
        top: 58% !important;
    }
    #createAccount .createAccountFormfields input[type='text'],
    #createAccount .createAccountFormfields input[type='number'],
    #createAccount .createAccountFormfields input[type='password']{
       background: #FFFFFF !important;
       width: 100%;
    }
    .updatePasswordClassSubHeader{
        width: 45%;
    }
    .changePasswordInputDiv{
        width: 52%;
    }
    .passwordInputBox{
        width: 100%;
    }
    #createupdateArchiveButton{
        padding: 4px 35px !important;
    }
    .NewStudentTableTDGeneric input{
        width: 130px !important;
        font-size: 11px;
    }
    #addStudentPopupDiv .NewStudentTableTDGeneric input{
        width: 160px !important;
    }
    #addStudentPopupDiv .qefGradeDropdown{
        width: 160px !important;
    }
    .qefGradeDropdown{
        width: 130px !important;
        font-size: 11px;
    }
    .addStudentDropdownContent{
        min-width: 130px;
    }
    .addStudentDropdownContent li{
        font-size: 11px !important;
    }
    .modal-content,
    .modal-dialog,
    .modal-body {
        height: inherit;
    min-height: 100%;
    }
    .modal {
        min-height: 100%;
    }
    .modal-content,
    .modal-dialog,
    .modal-body {
        height: inherit;
    min-height: 100%;
    }
    .modal {
        min-height: 100%;
    }
    #addStudentLinkDiv{
        text-align: center;
    }
    #addStudentLinkDiv button{
        padding: 10px 20px;
        font-size: 10px;
        border-radius: 30px;
    }
    .mainSubContainer{
        margin: 20px !important;
    }
    #shapeSelectionDiv{
        padding: 15px 0px !important;
    }
    .easyloginshapes-scrollable-div{
        max-height: 200px;
    }
    .accesscodeInfoText{
        margin: 20px 0px 0px 0px !important;
    }
    #createupdateCancelButton{
        width: 35% !important;
    }
    #createupdateSubmitButton{
        width: 35% !important;
    }
    .studentOrgDropdown{
        width: 50%;
    }
    .easy-login-grey-background{
        border-radius: 0px !important;
        width: 100%;
    }
    .easy-login-grey-border{
        border-radius: 0px !important;
        width: 100%;
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    .pagecontainer #topnav{
        height: auto !important;
    }
    #navigation h1{
       padding: 40px 0px 0px 30px !important;
    }
    #signInContainer{
        padding: 0px 20px !important;
    }
    .loginPageBtns{
        display: block !important;
        width: 100% !important;
        float: none !important;
        margin-top: 15px;
    }
    #loginContainer{
        padding: 20px !important;
    }
    #loginContainer .signInFormFields input{
        width: 100%;
        border-radius: 0px;
        background: #FFFFFF !important;
    }
    #loginButton{
        width: 100% !important;
    }
    .productHome{
        text-align: center;
    }
    #accessCodeButton{
        margin: 5px;
    }
    #schoolProductsDiv h3{
        text-align: center !important;
    }
    #productsOrgTypeAhead{
        width: 100%;
        text-align: left;
    }
    #subscriptionSchoolTypeAhead{
        width: 100%;
        text-align: left;
    }
    .schoolProductsInnerDiv {
        margin: 20px 10px 0px 0px !important;
    }
    .applicationIcon.studentAppIcon{
        margin: 20px 10px 0px 0px !important;
    }
    .loaderGIF{
        left: 35% !important;
        top: 45% !important;
    }
    #createAccount .createAccountFormfields input[type='text'],
    #createAccount .createAccountFormfields input[type='number'],
    #createAccount .createAccountFormfields input[type='password']{
       background: #FFFFFF !important;
       width: 100%;
    }
    #termsDiv{
       font-size: 10px !important;
    }
    #createAccount .createAccountFormfields input[type='checkbox']{
        vertical-align: top;
    }
    .logintopcontainerText a{
        display: block;
    }
    #createAccountButtons button{
        width: 100% !important;
    }
    #createAccountButtons .cancelbtn{
        margin-bottom: 20px;
    }
    .updatePasswordClassSubHeader{
        width: 100%;
    }
    .changePasswordInputDiv{
        width: 100%;
    }
    .passwordInputBox{
        width: 100%;
    }
    #changePasswordFooter button{
        width: 100%;
    }
    #changePasswordCancelBtn{
        margin-bottom: 20px;
    }
    #changePasswordDiv{
        padding: 35px 40px !important;
    }
    #changePasswordFooter{
        padding: 20px 0px !important;
    }
    .createUpdateClassSubHeader{
        width: 100% !important;
    }
    .createUpdateClassSubSection .classinput{
        width: 100% !important;
        float: none !important;
    }
    #classDisplayName{
        padding-top: 0px !important;
    }
    #classTitleInput{
        font-weight: 500 !important;
    }
    #schoolNameText{
        width: 100% !important;
    }
    #createupdateCancelButton{
        width: 100% !important;
        margin-bottom: 20px;
    }
    #createupdateSubmitButton{
        width: 100% !important;
    }
    #createupdateArchiveButton{
        padding: 4px 35px !important;
    }
    .NewStudentTableTDGeneric{
        display: block;
    }
    .NewStudentTableTDGeneric input{
        width: 130px !important;
        font-size: 11px;
    }
    .qefGradeDropdown{
        width: 130px !important;
        font-size: 11px;
    }
    .addStudentDropdownContent{
        min-width: 130px;
    }
    .addStudentDropdownContent li{
        font-size: 11px !important;
    }
    #editClassLinkDiv{
        padding: 20px 0px 0px 0px !important;
        display: block !important;
        text-align: center;
    }
    #editClassLinkDiv a{
        margin-left: 0px !important;
    }
    #editClassInfoLink{
        margin-left: 0px !important;
    }
    #classDropdownDiv{
        padding: 20px 15px !important;
    }
    #enrollmentTableDiv{
       padding: 20px 15px !important;
    }
    #addStudentPopupDiv{
        padding: 0px 30px !important;
    }
    #qefEdit, #qefSave{
        width: 100%;
    }
    #qefEdit{
        margin-bottom: 10px;
    }
    #addStudentPopupSaveBtn{
        margin-top: 15px;
    }
    #addStudentPopupCancelBtn,
    #addStudentPopupValidateBtn,
    #addStudentPopupSaveBtn,
    #addStudentPopupEditBtn{
        width: 120px !important;
    }
    #addStudentPopupCancelBtn{
        margin-right: 10px !important;
    }
    #addStudentPopupEditBtn{
        margin: 0px;
    }
    #studentListTable{
        width: 100% !important;
    }
    #printStudentLink, #addStudentLink, #exportStudentLink{
        display: block !important;
    }
    #addStudentDropdownButton,#exportRosterButton{
        width: 100%;
        margin-left: 0px !important;
    }
    #addStudentDropdownButton{
        margin-top: 20px;
    }
    #userNameDiv h3{
        font-size: 18px !important;
    }
    #licenceAcceptHeader{
        font-size: 14px !important;
    }
    #licenseAcceptBtn{
        width: 100%!important;
        padding: 8px 50px!important;
    }
    #licenseAcceptCancelBtn{
        width: 100%!important;
        padding: 7px 50px!important;
        margin-bottom: 20px;
    }
    .editClassBtn{
        width: 100% !important;
        margin-top: 20px;
    }
    .subnav-content-grid{
        padding: 10px 20px !important;
    }
    .cancelbtn{
        margin-right: 10px;
    }
    .cancelbtn.editClassBtn{
        margin-top: 0px !important;
    }
    .editClassName-subdiv label{
        width: 100%;
        margin-bottom: 10px;
    }
    #classDropdownButton, #schoolYearDropdownBtn{
        width: 100% !important;
    }
    .classDropdownSpan{
        display: block;
    }
    .mainSubContainer{
        margin: 20px !important;
    }
    .enrollmentApps{
        padding: 0px 15px !important;
    }
    .studentInfoUpdateSection .studentInfoInputDiv{
        width: 100% !important;
    }
    .studentInfoUpdateLabels{
        display: block;
        width: 100% !important;
        margin-bottom: 10px;
    }
    #studentInfoCancelbtn,#studentInfoSavebtn{
        width: 100% !important;
    }
    #studentInfoCancelbtn{
        margin-bottom: 20px;
    }
    #studentInfoDeactivatebtn{
        width: 100%;
    }
    #shapeSelectionCancelBtn{
        width: 100% !important;
        height: 30px;
        margin-bottom: 20px;
    }
    #shapeSelectionSaveBtn{
        width: 100% !important;
        height: 30px;
    }
    .easyloginshapes-scrollable-div{
        max-height: 400px;
    }
    .studentInfoEasyLoginSubDiv {
        padding: 20px 10px 0 !important;
    }
    .selectSchoolSubDivInline{
        text-align: center !important;
    }
    .teacherViewToggleDiv{
        text-align: center !important;
    }
    .printbtnsSubDiv{
        margin: 20px;
    }
    .selectSchoolContainerDiv{
        border-spacing: 0px !important;
    }
    #selectSchoolConfirmChkBoxDiv{
        padding: 20px 0px 0px 0px !important;
    }
    .selectSchoolBtnDiv button{
        width: 100%;
    }
    .selectSchoolCancelBtn{
        margin-bottom: 20px;
    }
    #selectSchoolConfirmChkBoxDiv label{
        max-width: 85% !important;
    }
    .selectSchoolSubDivInline{
        padding: 20px !important;
    }
    .searchSchoolByZipCancelBtn{
        padding-top: 30px !important;
        text-align: center !important;
    }
    .accessCodeFormSubDiv,
    .accessCodeFormMainDiv h3{
        display: block !important;
        margin: 20px 0px 0px 0px !important;
    }
    .createAccountMainContainer{
        padding: 20px !important;
    }
    .addNewStudents-btns button,
    .initialOnBoardingBtnDiv button{
        width: 100%;
    }
    .addNewStudents-btns .cancelbtn,
    .initialOnBoardingBtnDiv .cancelbtn{
        margin-bottom: 20px;
    }
    #studentInfoMainDiv{
        padding: 40px !important;
    }
    #studentInfoPopupFooterDiv1{
        padding: 20px 0px !important;
    }
    #studentInfoPopupFooterDiv2{
        padding: 0px !important;
    }
    #createUpdateClass{
        padding: 40px 20px !important;
        margin: 20px !important;
    }
    #printDiv{
        padding: 40px 20px !important;
        margin: 40px !important;
    }
    .studentOrgDropdown{
        width: 100%;
    }
    .easy-login-grey-background{
        border-radius: 0px !important;
        width: 100%;
    }
    .easy-login-grey-border{
        border-radius: 0px !important;
        width: 100%;
    }
    .subnav-content-subdiv{
        padding: 30px 0px 20px 0px !important;
    }
}

/* iphone 5 portrait mode styles */
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){
	#navigation h1{
	   font-size: 26px !important;
	}
    #accessCodeButton{
        margin: 0px 0px 0px 15px;
    }
	#createUpdateClass,#createUpdateClassConfirmModal{
	   padding: 20px !important;
	}
	#enrollmentTableDiv{
	   padding: 20px 20px !important;
	}
	#addStudentPopupCancelBtn,
    #addStudentPopupValidateBtn,
    #addStudentPopupSaveBtn,
    #addStudentPopupEditBtn{
        width: 100px !important;
        padding: 2px 0px;
    }
    .subnav-content-grid{
        padding: 10px !important;
    }
    .easyloginshapes-scrollable-div{
        max-height: 300px;
    }
    #createUpdateClass{
        padding: 20px !important;
        margin: 10px !important;
    }
    #printDiv{
        padding: 40px 20px !important;
        margin: 20px !important;
    }
    .easyLoginToggleDiv{
        padding: 40px 0px 20px 0px !important;
    }
}

/* iphone 5 landscape mode styles  */
@media screen and (device-width: 568px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2){
	#signInContainer{
        padding: 0px 20px !important;
    }
    #accessCodeButton{
        margin: 0px 0px 0px 15px;
    }
     .NewStudentTableTDGeneric{
        display: block;
    }
    .NewStudentTableTDGeneric input{
        width: 175px;
    }
    .qefGradeDropdown{
        width: 155px !important;
    }
    #editClassLinkDiv{
        padding: 20px 0px !important;
    }
    #editClassInfoLink{
        margin-left: 0px !important;
    }
    #addStudentLinkDiv button {
        padding: 5px 10px;
    }
    .easyloginshapes-scrollable-div{
        max-height: 150px;
    }
    #termsDiv label{
        font-size: 10px !important;
    }
    #createUpdateClass{
        margin: 20px !important;
    }
    #printDiv{
        padding: 40px 10px !important;
        margin: 40px 10px !important;
    }
}

/*
JH DotComIt 2/17/2016
Added for the alert message box in the new layout
*/

.alert-star-div{
    background: #1FB71A;
    color: #FFFFFF;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}
.alert-warning-div{
    background: #f5a623;
    color: #FFFFFF;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}
.alert-text-div{
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}
.messageAlert{
    border: 2px solid #1FB71A;
    border-radius: 6px;
    background: #FFFFFF;
    margin: 15px 15px 10px 15px;
    padding: 0px;
    word-break: break-word;
}
.messageAlert-errorFlag{
	border: 2px solid #f5a623;
	border-radius: 6px;
	background: #FFFFFF;
	margin: 15px 15px 10px 15px;
	padding: 0;
	word-break: break-word;
}

.messageAlert-errorMsg{
    background: #FFE500;
    border: none;
    margin: 15px 15px 10px 15px;
    border-radius: 6px;
    color: #000000;
    display: inline-block;
}
.alert-dismissible .close,
.alert-dismissible .close:focus{
    position: inherit;
    padding: 10px 5px;
    background: #ffffff;
    margin-right: 5px;
}

.noMarginAlerts{
    margin: 15px 0px 10px 0px !important;
}

#footercontainer {
	font-family: 'Museo Sans W01_500',arial;
	text-align:center;
	color: #6a6a6a;
	padding: 20px 10px 15px;
	clear: both;
}
#footercontainer a{
	color: #282829;
	outline: none;
}
#footercontainer a:hover,
#footercontainer a:focus {
	color: #101011;
	text-decoration: underline;
	outline: none;
}
#footercontainer .footercontainer-pp{
	font-family: 'Museo Sans W01_700',arial;
	font-size: 16px;
	font-weight: bold;
	color: #5a388a;
}
#footercontainer .footercontainer-pp:hover,
#footercontainer .footercontainer-pp:focus {
	color: #3e077b;
	text-decoration: underline;
	outline: none;
}
.schNumber{
	color: #000000;
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
}
#helpLink{
	padding-right: 15px;
	border: none;
}
#helpLink .active{
	outline: none;
}
#helpImageLink:focus,#helpImageLink:hover{
	outline: none;
}
.helpImage{
    font-size: 28px;
    margin-top: -4px;
    color: #FFFFFF;
}
.boldText{
	font-family: 'Museo Sans W01_700',arial;
}
.userDropdown-images{
	top: 0px;
	margin-top: -2px;
}
.user-icon{
	padding-right: 5px;
}
.userDropdown-chevron{
	padding-left: 5px;
}
.modal-content{
	box-shadow: none !important;
	border-radius: 10px !important;
}
.loaderGIF{
	z-index: 2000;
	position: fixed;
	left: 46%;
	top: 45%;
}
#custName{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    -o-text-overflow: ellipsis;
    max-width: 250px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 2px;
}
.custName-mobile-li{
    padding: 10px;
}

.col-centered{
    float: none;
    margin: 40px auto;
}
.col-no-float{
	float: none;
}
.xs-menu{
	display: inline-block;
	float: right;
	padding: 10px 10px 0px 0px;
	font-size: 34px;
}
.tabs-dropdown-btn{
	border: none;
	outline: none;
	background: none;
	margin: 0px;
	padding: 0px;
}
.tabs-dropdown .active{
	background: #337ab7;
}
.tabs-dropdown .active > a{
	color: #FFFFFF;
}
.mobile-user-icon{
	padding-right: 5px;
}
.btn:focus{
	outline: 0 !important;
}
.selectize-input{
    border-radius: 7px !important;
    background: #FFFFFF !important;
}
.selectize-input>*{
    vertical-align: sub !important;
}
.selectize-input.selectize-focus{
    border-color: #b8b8b8 !important;
}
.selectize-dropdown{
    margin: -7px 0px 0px;
}
.selectize-dropdown .active{
    color: #FFFFFF;
    background-color: #523084;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    font-style: italic;
    font-family: 'Museo Sans W01_500',arial;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-style: italic;
    font-family: 'Museo Sans W01_500',arial;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-style: italic;
    font-family: 'Museo Sans W01_500',arial;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-style: italic;
    font-family: 'Museo Sans W01_500',arial;
}
body.modal-open {
        overflow: hidden;
        height: 100%;
        position: fixed;
        width: 100%;
}
.close:focus{
	outline: none;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.sdm-links,
.sdm-links:hover{
    font-size: 14px;
    color: #523084;
    text-decoration: underline;
}
.red-text{
    color: #EC1D25;
}
.easy-login-generic{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial !important;
}
.glyph-generic{
    padding-right: 5px;
}
.glyph-middle{
    vertical-align: middle;
}
.backToDashboardBtnDiv{
    padding: 40px 0px 20px 40px;
}
.purple-bold-text{
    font-family: 'Museo Sans W01_700',arial;
    color: #523084 !important;
}
.red-background-btn{
    background: #EC1D25;
}
.red-background-btn:hover,
.red-background-btn:focus{
    background: #EC1D25;
}
.teacherViewToggleDiv{
    text-align: right;
    padding: 40px 40px 20px 40px;
}
.teacherViewToggleDiv a{
    color: #523084;
    text-decoration: none;
}
.teacherViewToggleDiv a:hover,
.teacherViewToggleDiv a:focus{
    color: #523084;
    text-decoration: none;
}
#sdmTitle{
    padding: 20px 10px;
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
}
.initialOnBoardingNav{
    text-align: center;
    padding: 40px 40px 0px 40px;
}
.initialOnBoardingNav .initial-onboarding-buttons {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.initialOnBoardingNav .initial-onboarding-buttons.text-right {
	justify-content: flex-end;
}
.initialOnBoardingText{
    text-align: center;
    padding: 30px 30px 0px 30px;
}
.initialOnBoardingSubText{
    padding-bottom: 20px;
}
.initialOnBoardingNavList{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.initialOnBoardingNavList li{
    display: inline-block;
    color: #7F7F7F;
    padding: 10px;
    font-size: 18px;
}
.initialOnBoardingBtnDiv{
    padding-top: 30px;
    text-align: right;
}
.onboarding-container-div{
    padding: 40px;
    background: #EBEBEB;
    max-width: 600px;
    margin: 40px auto;
    border-radius: 10px;
}
.collapsibleDiv-chevron{
    padding-left: 10px;
    vertical-align: text-top;
}
.collapsibleDivsHeader{
    padding: 10px 20px;
    margin: 0px;
    border-top: 2px solid #CCCCCC;
}
.collapsibleDivsHeader:hover{
    cursor: pointer;
}
.collapsibleDivsHeader h3{
    font-size: 18px;
    color: #5A388A;
    margin-top: 10px;
}
.collapsibleDivsContent{
    color: grey;
    padding: 10px 20px 40px 20px;
}
.panel{
    border: 0px;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.panel-group .panel+.panel{
    margin-top: 0px;
}


@media only screen and (max-width: 767px) {
	.logo {
		width: 172px;
	}
}

@media only screen and (min-width: 768px) {
	.logo {
		width: 23%;
	}
}


@media only screen and (min-width: 991px) {
	.logo {
		width: 18%;
	}
}

@media only screen and (min-width: 1024px) {
	.logo {
		width: 17%;
	}
}

@media only screen and (min-width: 1280px) {
	.logo {
		width: 14%;
	}
}

@media only screen and (min-width: 1920px) {
	.logo {
		width: 12%;
	}
}


@media only screen and (min-width: 2560px) {
	.logo {
		width: 8%;
	}
}
