.inner-container-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.inner-container-2 {
    align-items: flex-start;
}

.add-padding-right {
    padding-right:10px;
}

.class-background-panel {
    background: #ebebeb;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.main-container-panel , .inner-container-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
}

#enrollmentTableDiv{
    padding: 20px 30px;
    background-color: #EBEBEB;
    overflow-x: scroll;
}

.add-space {
    margin-top: 5px;
}

#enrollmentListTable {
    width:100%;
    font-family: 'Museo Sans W01_500',arial;
    border: 1px solid #CCC;
}

#enrollmentListTable th{
    border-bottom: 1px solid #CCCCCC;
    padding: 20px 0px 5px 0px;
    text-align: center;
    background: #FFFFFF;
}

.no-entitlements {
    padding:20px;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#000000;
}

.addStudentYOY {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#enrollmentListTable thead tr th:last-child{
    border-right: 1px solid #CCC;
}

#applicationImage{
    display: block;
    padding-bottom: 5px;
    margin: auto;
    width: 70px;
}
.enrollmentApps{
    min-height: 40px;
}
.subscriptionQuantity{
    font-size: 12px;
    font-weight: 500;
    color: #808080;
}
#enrollmentListTable td{
    height: 40px;
    text-align: center;
}

#enrollmentListTable tbody tr td:last-child{
    border-right: 1px solid #CCC;
}

#enrollmentListTable td:first-child{
    text-align: left;
    padding-left: 20px;
    color: #0c84e1;
}

#enrollmentListTable th:first-child{
    text-align: left;
    padding: 0px;
}
#enrollmentListTable td.cellNoBorder{
    border: none;
    background: #DEDBDE;
    color: #4A92E7;
    text-decoration: underline;
}
#enrollmentListTable td.cellNoBorder a:hover,
#enrollmentListTable td.cellNoBorder a:focus{
    color: #4A92E7;
    outline: none;
    border: none;
}
#enrollmentListTable .enrollmentList{
    background-color: #FFFFFF;
}
#enrollmentListTable .enrollmentListAlt{
    background-color: #EBEBEB;
}


.studentDetailRow:nth-child(even) {
    background-color: #EBEBEB;
}

.studentDetailRow:nth-child(odd) {
    background-color: #FFFFFF;
}

#enrollmentClassname{
    backgroud-color: #f5f5f5;
    font-size: 24px;
    text-align: center;
}
#noDataPresent{
    color: red;
    text-align: center;
    font-size: 24px;
}
input[type='checkbox']{
    cursor: pointer;
}
input[type='checkbox']:disabled{
    cursor: default;
}
#enrollmentListTable #tableHeaderLinkCol{
    font-weight: bold;
    text-align: left;
    padding-left:20px;
    cursor: pointer;
}

#placeholderDivTeacherEnt{
    height: 34px;
}
#classDropdownDiv{
    background: #EBEBEB;
    width: 100%;
    padding: 20px 30px;
}

#dropdownSchoolName{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px ;
    color: #523084;
    letter-spacing: 1px;
}

#dropdownSchoolName.space {
    margin-bottom: 16px;
    margin-top: 16px;
}

#enrollmentListBulkEnrollDiv,.restoreClassDiv{
    padding-left: 20px;
    padding-right: 20px;
}
#enrollmentListBulkEnrollDiv h3,.restoreClassDiv h3{
    font-family: 'Museo Sans W01_700',arial;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000000;
}

.restoreClassDiv .pad-bottom {
    padding-bottom: 12px;
}

.enrollmentListBulkEnrollInnerDiv{
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #000000;
}
#bulkEnrollbtn,.restoreClassBtn{
    margin-bottom: 25px;
    font-size: 11px;
}
#bulkEnrollbtnWithTopPadding{
    margin-top: 20px;
    margin-bottom: 25px;
}

.bulk-actions-dropdown .dropdown .dropdown-button {
    background-color: #ffffff;
    border: solid 1px #979797;
    border-radius: 2px;
    display: block;
    height: 40px;
    width: 213px;
}

.bulk-actions-dropdown .dropdown .dropdown-button[disabled] {
    border-color: #979797;
    opacity: 0.43;
}

.bulk-actions-dropdown .dropdown .dropdown-menu {
    border: solid 1px #979797;
    border-radius: 2px;
    border-top: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.bulk-actions-dropdown .dropdown .dropdown-menu li
{
    display: block;
    height: 40px;
}

.bulk-actions-dropdown .dropdown .dropdown-menu li a
{
    padding: 10px 12px;
}

.bulk-actions-dropdown .dropdown .dropdown-menu li a:hover
{
    background: #523084;
    color: #FFFFFF;
}

#classDropdownButton {
     width: 260px;
}

#classDropdownOptionsDiv {
    letter-spacing: 1px;
    width: 100% !important;
    margin: 0px !important;
}

#classDropdownOptionsDiv li a:hover
{
    background: #523084;
    color: #FFFFFF;
}

#editClassLinkDiv{
    display: inline-block;
}

@media (min-width: 1024px) {
    .add-class-btn-container {
        margin-left: auto;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

#editClassLinkDiv a{
    font-family: 'Museo Sans W01_700',arial;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
    text-decoration: underline;
    outline: none;
    color: #0c84e1;
    letter-spacing: 1px;
}
#addStudentLinkDiv{
    padding: 10px 0px 20px 0px;
    text-align: right;
}
#addStudentDropdownButtonDiv{
    padding-top: 10px;
    padding-bottom: 10px;
}

.addStudentDropdownButton {
    color: #523084;
    font-size: 30px !important;
}

.addStudentDropdownButton:hover{
    cursor: pointer;
}


#enrollAllRow td{
    font-family: 'Museo Sans W01_700',arial !important;
    font-weight: bold;
    color: #000000 !important;
    letter-spacing: 1px;
}
.content-package-name{
    min-height: 20px;
    font-size: 12px;
    color: #808080;
}
#ssoNoClassClassesDiv{
    text-align: center;
}
.noClassForCurrentSchoolLinks{
    color: #0c84e1;
    text-decoration: none;
    outline: 0;
}
.noClassForCurrentSchoolLinks:focus,.noClassForCurrentSchoolLinks:hover{
    color: #0c84e1;
    text-decoration: none;
    outline: 0;
}

.padding-fields{
	padding: 15px 0px;
}

.default-height {
    height: 10px;
}

#schoolYearDiv label{
	font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #523084;
    margin-right: 10px;
}

.only-one-school-year {
    line-height: 40px;
}

.dropdown-button{
	width: 260px;
}

@media (min-width: 1024px) and (max-width: 1106px) {
    .dropdown-button {
        width: 210px;
    }
}

#schoolYearDropdownOptions{
    letter-spacing: 1px;
    width: 100%!important;
    margin: 0!important;
}
#cleverSchoolNoStudentsMsg,#nonCurrentYearClassMsg{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
}

#settingsDropdownDiv{
    text-align: center;
}

@media (max-width: 480px) {
    #settingsDropdownDiv.space-top-xs {
        margin-top: 20px;
    }
}

@media (min-width: 481px) {
    #settingsDropdownDiv {
        text-align: right;
        float: right;
        margin: 0;
    }
}

#settingsDropdownBtn{
    width: 180px;
}
.settings-glyph{
    font-size: 18px;
}
#classNameLabel{
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #523084;
    margin-right: 10px;
}

#singleClassNameDiv{
    font-size: 16px;
    display: inline-block;
}

@media (min-width: 481px) {
    #singleClassNameDiv {
        line-height: 40px;
    }
}

/* Google Classroom */
.google-manage-access-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.google-manage-access-import-from-google-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-manage-access-import-from-google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 33px 0;
    cursor: pointer;
    width: 175px;
    height: 40px;
    border-radius: 24.5px;
    border: solid 1px #979797;
    background-color: #ffffff;
}

@media (max-width: 767px) {
    .google-manage-access-import-from-google-button {
        width: 160px;
    }
}

.google-manage-access-import-from-google-button img {
    padding: 0 10px 0 0;
    display: inline-block;
    height: 23px;
}

.google-manage-access-import-from-google-button span {
    display: inline-block;
    height: 18px;
    font-family: 'Google Sans';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

button.google-manage-access-settings-button {
    width: 40px !important;
    height: 40px;
    border-radius: 40px;
    border: solid 1px #979797;
    background-color: #ffffff;
    display: flex;
    justify-items: center;
    justify-content: center;
}

.google-manage-access-drowpdown-school-name {
    font-size: 20px !important;
    color: #4c357f;
}

button.google-manage-access-settings-button .glyphicon-cog:before {
    font-size: 22px;
}

.google-manage-access-controls-dropdown-label {
    font-size: 14px !important;
    letter-spacing: 0 !important;
    color: #333 !important;
}

.student-home-manage-roster-first-panel-first-row {
    margin: 0 0 10px 0;
}

@media (max-width: 630px) {
    .student-home-manage-roster-first-panel-first-row {
        width: 65%;
    }
}

.student-home-manage-roster-controls-first-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
}

.student-home-manage-roster-first-panel-last-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-between;
    width: 100%;
}

@media (max-width: 1066px) {
    .student-home-manage-roster-first-panel-last-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: space-between;
        width: 65%;
    }
}
.student-home-manage-roster-first-panel-last-row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.google-manage-access-controls-left-panel-last-row-item {
    display: inline-flex;
    flex-direction: column;
    margin: 0 40px 0 0;
    padding-bottom: 15px;
}

.google-classroom-deactivated-student-name {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px !important;
    color: #000000 !important;
}

.enrollment-list-bulk-enroll-inner-div {
    max-width: 405px;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #000000;
}

.student-home-manage-access-add-students-panel-text {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px !important;
    color: #000000 !important;
    margin-left: 5px;
}


@media only screen and (max-width: 480px) {
    .inner-container-2, .main-container-panel {
        flex-basis: 50%;
    }

    .inner-container-2 {
        padding-top:10px;
    }

    #editClassLinkDiv.no-pad-xs {
        margin-top: 0px;
        padding: 0px !important;
    }

    .default-height {
        height: 0px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
    .inner-container-2, .main-container-panel {

    }

    .inner-container-2 {
        padding-top:10px;
    }

    .default-height {
        height: 0px;
    }

}

@media only screen and (min-width: 600px) and (max-width: 767px) {

    .inner-container-2 {
        padding-top:10px;
    }
}

@media only screen and (min-width: 768px) {
    .default-height {
        height: 22px;
    }
}

/* new checkbox styles */

.item-checkbox-input-label-container {
    display: inline-block;
    width: 78%;
}

.item-checkbox-container {
    display: inline;
    width: 14px;
    padding-right: 20px;
    position: relative;
}

.item-checkbox-container .item-checkbox-input {
    text-decoration: none;
    margin: 0;
    -ms-transform: scale(.1); /* IE */
    -moz-transform: scale(.1); /* FF */
    -webkit-transform: scale(.1); /* Safari and Chrome */
    -o-transform: scale(.1); /* Opera */
    transform: scale(.1);
}

.item-checkbox-container .item-checkbox-input-display {
    cursor: pointer;
    position: absolute;
    max-width: unset;
    top: 0;
    left: 0;

    width: 14px;
    height: 13px;
    border-radius: 2px;
    border: solid 1.5px #979797;
    background: #ffffff;
}

.item-checkbox-input-display:hover {
    border: solid 1.5px #3d98f5;
    background: #3d98f5;
}

.item-checkbox-container .item-checkbox-input-display:after {
    transform: rotate(-45deg);
    opacity: 0.02;
    content: '';
    position: absolute;
    top: 1.4px;
    left: 1.3px;

    width: 9px;
    height: 5px;
    background: transparent;
    border: 2.1px solid #fff;

    border-top: none;
    border-right: none;
}

.item-checkbox-container .item-checkbox-input-display:hover::after {
    opacity: 1;
}

.item-checkbox-container input[type=checkbox]:checked + .item-checkbox-input-display:after {
    opacity: 1;
}

.item-checkbox-container input[type=checkbox]:checked + .item-checkbox-input-display {
    background: #3d98f5;
    border: solid 1.5px #3d98f5;
}

.active-class-student-link {
    text-decoration: underline;
}

.active-class-student-link:hover{
    cursor: pointer;
    cursor: hand;
}

/* new class roster student entitlements panel */

#enrollmentListBulkEnrollDiv h3 {
    min-width: 175px;
}

.class-roster-student-entitlements-panel-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    align-content: center;
    min-height: 73.5px;
}

.class-roster-student-entitlements-panel-header.current-school-year
{
    padding-top: 16px;
}

.class-roster-student-entitlements-panel-header div {
    margin-left: 20px;
}

.class-roster-student-entitlements-panel-buttons-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    align-content: center;
    width: 441px;
}

#printRosterButton{
    display: block;
    margin-top: 15px;
}

.print-roster-button {
    order: 2;
    width: 175px;
    display: block !important;;
    margin-bottom: 15px !important;
    border: solid 1px #4c357f;
    background: #fff !important;
    color: #4c357f;
    height: 40px;
}

.print-roster-button span {
    color: #4c357f;
}

.print-roster-button:hover {
    color: #4c357f;
}

#showInactiveStudentsBtn{
    margin-bottom: 15px;
}

.show-inactive-students-btn {
    order: 1;
    width: 175px;
    margin-bottom: 15px !important;
    height: 40px;
    padding: unset;
}

.class-roster-student-entitlements-panel-buttons-container .show-active-students-btn {
    order: 1;
    width: 175px;
    margin-bottom: 15px !important;
    height: 40px;
}

@media (max-width: 767px) {
    #printRosterButton,#showInactiveStudentsBtn{
        width: 180px;
        padding: 10px !important;
        white-space: normal;
        border-radius: 30px !important;
        height: auto;
    }

    .print-roster-btn, .show-inactive-students-btn {
        width: 180px !important;;
        padding: 10px !important;
        white-space: normal !important;;
        border-radius: 30px !important;
        height: 40px !important;
    }
}

@media (max-width: 824px) {
    .class-roster-student-entitlements-panel-header {
        flex-direction: column;
    }

    .class-roster-student-entitlements-panel-header
    div.class-roster-student-entitlements-panel-buttons-container {
        margin-left: 0px;
        max-width: 441px;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }

    .print-roster-button {
        order: 1;
        margin-right: 20px;
    }

    .show-inactive-students-btn {
        order: 2;
    }
}

@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    #printRosterButton{
        width: auto !important;
    }

    .print-roster-button {
        width: auto !important;
    }
}

#enrollmentTableDiv.add-space .add-new-class-panel .google-manage-access-import-from-google-button {
    width: 205px;
}

#enrollmentTableDiv.add-space .add-new-class-panel {
    padding: 40px 0 0 0;
    border-radius: 5px;
    width: 95%;
    margin: auto;
}

#noActiveStudentsRestoreClass {
    background: white;
    border: 1px solid #ccc;
}

.google-class-dropdown .dropdown-menu-item-name,
.google-class-dropdown .dropdown-menu-item-section {
    display: block;
}