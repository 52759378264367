.img-upload-csv-template{
    clear: both;
    display: inline-block;
    margin: 1em;
}

.uploadCsvButton.btn {
    margin-top: unset !important;
}

.table-header-title {
    max-width: 619px;
    width: 100%;
}

.student-import {
    max-width: 794px;
    width: 100%;
}

.student-import-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 619px;
    width: 100%;
}

.student-import-buttons-container > a, .student-import-buttons-container > label {
    margin-bottom: 2em;
}

@media all and (min-width: 619px) {
    .student-import-buttons-container {
        flex-direction: row;
    }

    .student-import-buttons-container > a, .student-import-buttons-container > label {
        margin-bottom: unset;
    }
}
