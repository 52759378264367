
.clear-float-details {
	clear: both;
}

.fullscreen-details {
	width: 100% !important;
}

.content-grid-subscriptionDetails-bg {
	padding-left: 20px;
	padding-bottom: 40px;
	background: #ebebeb;
	border-radius: 6px;
}

.btn-padding {
    padding-top: 10px;
}

.wrapper-subscription-detail {
    width: 100%;
}

.subnav-content-grid-subscriptionDetails {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom :40px;
	float: left;
	width: 85%;
}

.spacer {
	height: 30px;
}
#subscriptionDetailsMainDiv{
	font-family: 'Museo Sans W01_500',arial;
	min-height: 500px;
}

#subscriptionDetailsHeader{
	text-align: center;
}

#subscriptionDetailsHeader h3{
	display: inline-block;
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
	padding: 0px;
	color: #523084;
}

#subscriptionDetailsHeader a{
	display: inline-block;
	float: right;
}

.subscriptionInnerDiv{
    float: left;
	padding: 20px 40px 0px 40px;
	font-size: 14px;
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
	clear: both;
}

#copyAccessCodebtn{
	width: 100px;
	padding: 3px 0px;
	font-size: 10px;
}

#accessCode{
	color: #4A90E2;
	font-size: 24px;
}

#accessCodeInnerDiv,#subscriptionCount,#subscriptionStartDate,#subscriptionEndDate{
	padding-top: 10px;
}

.subscriptionInnerSubDiv{
	font-size: 14px;
}

.subscriptionCountValue{
	color: #4A90E2;
	font-weight: bold;
}

#subscriptionTeacherListTable{
	font-family: 'Museo Sans W01_500',arial;
	font-size: 14px;
	font-weight: normal;
	margin-top: 10px;
	width: 100%;
	border: 1px solid #CCCCCC;
}

#subscriptionTeacherListTable .subscriptionList{
	background: #FFFFFF;
}

#subscriptionTeacherListTable .subscriptionListAlt{
	background: #EBEBEB;
}

#subscriptionTeacherListTable th{
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
	height: 40px;
	padding-left: 20px;
	border-bottom: 1px solid #CCCCCC;
	background: #FFFFFF;
}

#subscriptionTeacherListTable td{
	height: 40px;
	padding: 10px 20px;
	color: #000000;
	word-break: break-word;
	word-break: break-all;
}

#subscriptionTeacherListTable td:nth-of-type(1){
	word-wrap: break-word;
	word-break: break-all;
}

#subscriptionTeacherListTable td:nth-of-type(3){
	text-align: right;
	padding-right: 20px;
}

.stdbtns.subscriptionRevoke{
	font-size: 10px;
	width: 100px;
	padding: 2px 0px;
	background: #FFFFFF;
	color: #523084;
	border: 1px solid #523084;
}

.stdbtns.subscriptionRevoke:hover{
	background: #523084 !important;
	color: #FFFFFF !important;
	border: 1px solid #523084 !important;
}

.stdbtns.subscriptionRevoke:focus{
	background: #FFFFFF;
	color: #523084;
	border: 1px solid #523084;
}
#subscriptionCloseImg{
	height: 15px;
}

#subscriptionSchoolName{
	font-weight: normal;
	padding-top: 10px;
	font-size: 14px;
}
#subscriptionDetailsSubDiv,#applicationImageDiv{
	display: inline-block;
}
#applicationImageDiv{
	float: right;
	padding: 20px 40px 0px 0px;
}
#subscriptionDropdownBtn{
	width: 250px;
}
#subscriptionOrgName{
	font-size: 16px;
    margin-bottom: 10px;
    color: #523084;
}
#cleverTeacherSearchBox{
	width: 200px;
    margin: 10px 0px;
    border: 1px solid #CCCCCC;
    padding: 5px;
}
.backbtn{
	background: #FFFFFF !important;
	color: #523084 !important;
	border: 1px solid #523084 !important;
}
.back-glyph{
	padding-right: 5px;
}
#cleverAdminDetailsTableDiv{
	padding-top: 0px !important;
}
.subscription-right-chevron{
	padding-left: 5px;
}
.subscriptionDetailsDropdown{
	width: 100%;
}
.subscriptionDroprdownWrapper{
    float: left;
    margin-right:10px;
}
#subscriptionTeacherListDiv{
    float:none;
}

@media only screen and (max-width: 460px) {
    .subscriptionInnerDiv {
        padding: 20px 38px 0 10px;
        font-size: 14px;
        font-family: 'Museo Sans W01_700', arial;
        font-weight: 700;
        clear: right;
    }
}

@media only screen and (max-width: 459px) {
	.btn-padding {
		padding-top: 10px;
		margin-bottom: 10px;
		text-align: left;
	}
}

@media only screen and (max-width: 767px) {
	.subnav-content-grid-subscriptionDetails {
		width: 100%;
	}
	.subscriptionDroprdownWrapper{
        width:100%;
        margin-right:0;
    }
}

@media only screen and (min-width: 768px) {
	.subnav-content-grid-subscriptionDetails {
		width: 77%;
	}
}


@media only screen and (min-width: 992px) {

	.subnav-content-grid-subscriptionDetails {
		width: 82%;
	}
}


@media only screen and (min-width: 1024px) {
	.subnav-content-grid-subscriptionDetails {
		width: 83%;
	}
}

@media only screen and (min-width: 1280px) {
	.subnav-content-grid-subscriptionDetails {
		width: 86%;
	}
}

@media only screen and (min-width: 1366px) {
	.subnav-content-grid-subscriptionDetails {
		width: 86%;
	}
}

@media only screen and (min-width: 1920px) {
	.subnav-content-grid-subscriptionDetails {
		width: 88%;
	}
}

@media only screen and (min-width: 2560px) {
	.subnav-content-grid-subscriptionDetails {
		width: 92%;
	}
}


