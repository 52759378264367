.no-class-panel {
    width: 100%;
    height: 299px;
    background-color: #ebebeb;
}

.no-class-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.noClass-sub-container {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 95%;
    margin-left:auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.no-class-header-title-text {
    font-family: 'Museo Sans W01_700',arial;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4c357f;
}

.no-class-description-text {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    margin-top: 30px;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto
}