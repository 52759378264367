.stud-list {
    margin-top : 10px;
    max-height: 250px;
    overflow: auto;
    border: 1px solid #e0d7d7;
    border-radius: 5px;
    text-align: left;
    font-family: 'Museo Sans W01_500',arial;

}

.stud-name {
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}