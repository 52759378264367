.import-classes-splash-container {
    margin-top: 37px;
    margin-bottom: 100px;
    padding-left: 0;
    padding-right: 0;
    max-width: 713px;
    min-width: 342px;
    text-align: center;
}

.import-classes-splash-top-container {
    padding: 0 0 51px 0;
    text-align: center;
}

.import-classes-splash-top-container h1 {
    padding: 5px 0 0 0;
    color: #523084;
}

.import-classes-splash-top-container-title {
    padding: 5px 0 0 0;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #64129c;
}

.import-classes-splash-top-container-text {
    display: none;
}

.import-classes-splash-top-container-text span {
    letter-spacing: 0;
}

.import-classes-splash-top-container-text a,
.import-classes-splash-top-container-text a:hover {
    font-size: 18px;
}

.import-content-container {
    display: flex;
    flex-direction: column;
    padding: 41px 0 41px 0;
    background: #ebebeb;
    border-radius: 5px;
}

.import-content-header-container {
    padding: 5px 0 0 0;

}

.import-content-header-text-container {

}

.import-content-header-text {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.import-content-body-container {
    padding: 25px 20px 49px 20px;
}

.import-content-body-text {
    font-family: 'Museo Sans W01_300', arial;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.import-courses-from-google-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#import-courses-from-google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 51px 0;
    width: 277px;
    height: 55px;
    border-radius: 27.5px;
    background-color: #ffffff;
    cursor: pointer;
}

#import-courses-from-google-button img {
    padding: 0 10px 0 0;
    display: inline-block;
}

#import-courses-from-google-button span {
    display: inline-block;
    font-family: 'Google Sans';
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

@media (min-width: 768px) {
    .import-classes-splash-top-container-text {
        display: block;
        padding: 25px 0 0 0;
        font-family: 'Museo Sans W01_300', arial;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }
}

.import-content-container {
    max-width: 713px;
    margin-left: 24px;
    margin-right: 5%;
}

@media (min-width: 481px) {
    .import-content-container {
        margin-right: 5%;
        margin-left: 5%;
    }
    .import-content-body-container {
        padding: 25px 20px 49px 20px;
    }
}

@media (min-width: 613px) {
    .import-content-container {
        margin-right: 3%;
        margin-left: 3%;
    }
    .import-content-body-container {
        padding: 25px 35px 49px 35px;
    }
}

@media (min-width: 767px) {
    .import-content-container {
        margin-right: 12%;
        margin-left: 12%;
    }
    .import-content-body-container {
        padding: 25px 35px 49px 35px;
    }
}

@media (min-width: 768px) {
    .import-content-container {
        margin-right: 4%;
        margin-left: 4%;
    }
    .import-content-body-container {
        padding: 25px 47px 49px 47px;
    }
}

@media (min-width: 1200px) {
    .import-classes-splash-container {
        width: 713px !important;
    }
    .import-content-container {
        width: 713px !important;
    }
    .import-content-body-container {
        padding: 25px 47px 49px 47px;
    }
}
