.submenu-space {
    padding-top: 20px !important;
    padding-bottom: 15px;
}

.left-side {
    float: left;
     width: 65%;
}

.right-side {
    width: 35%;
    float: left;
    text-align: left;
}

.float-right {
    text-align: left;
}


.submenu-toggle-div {
    text-align: right;
    padding: 20px 10px 20px 0;
}

.submenu-toggle-div a {
    color: #523084;
    text-decoration: none;
}


@media only screen and (min-width: 460px) and (max-width: 768px) {

    .left-side {
        width: 50%;
    }

    .right-side {
        width: 45%;
    }
}

@media only screen and (max-width: 991px) {
    .left-side {
        width: 99%;
    }

    .right-side {
        width: 99%;
    }

    .submenu-toggle-div {
        text-align: left;
    }
}


@media only screen and (min-width: 992px) {
    .left-side {
        width: 65%;
    }

    .right-side {
        width: 35%;
    }
    .submenu-toggle-div {
        text-align: right;
        padding: 20px 10px 20px 0;
    }
}
