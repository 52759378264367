#unAssignCoTeachers{
    padding: 20px;
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: 1px;
}
#revokeAccessButtons{
    text-align: center;
}

.revokeAccessHeader{
    font-family: 'Museo Sans W01_500',arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
}


.teacherMessage {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 30px;
    text-align: center;
}

.teacherName {
    font-weight: bold;
}