#revokeAccessPopup{
	padding: 20px;
	font-family: 'Museo Sans W01_500',arial;
	letter-spacing: 1px;
}
#revokeAccessButtons{
	text-align: center;
}
#revokeAccessHeader{
	font-size: 18px;
	text-align: center;
	margin-top: 0px;
	margin-bottom: 20px;
}