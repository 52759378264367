.el-pref-card-container {
    text-align: center;
    margin: auto;
}
.el-pref-header{
    text-align: center;
    padding: 10px 0;
}
.el-pref-header h4 {
    color: #523084;
    font-weight: bold;
}
.el-pref-card {
    padding: 20px 40px;
    margin: 20px;
    text-align: center;
    border: 2px solid #979797;
    border-radius: 6px;
    display: inline-block;
    background: #fff;
    vertical-align: top;
    max-width: 350px;
    min-height: 520px;
}
.el-pref-card h4 {
    color: #523084;
    font-weight: bold;
    margin: 0px;
    padding: 20px 0px;
}
.el-pref-card-header {
    padding: 20px 0px;
    font-family: 'Museo Sans W01_300',arial;
    font-size: 10px;
    color: #919191;
    min-height: 125px;
}
.el-pref-card-body {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 12px;
    display: inline-block;
    max-width: 280px;
    padding-bottom: 20px;
    color: #525252;
    text-align: center;
}
.el-pref-card-up label {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1px;
    color: #919191;
    width: 75px;
}
.el-pref-card-up span{
    border: 1px solid #979797;
    padding: 5px 0px;
    display: inline-block;
    width: 90px;
    margin: 5px 0px;
    border-radius: 6px;
}
.el-pref-toggle-div {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 12px;
    font-style: italic;
    max-width: 200px;
    text-align: center;
    margin: auto;
}
.el-pref-btn-div {
    display: inline-block;
}
.el-pref-btn {
    border: 3px solid #ccc;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    margin: 5px 10px;
}
.el-pref-toggle-glyph{
    color: #70d02c;
}
.el-toggle-custom-text-span {
    padding-left: 60px !important;
}
.el-pref-glyph-inline{
    display: inline-block;
    max-width: 170px;
    color: #ccc;
}
.el-pref-glyph-inline-text {
    display: inline-block;
    max-width: 170px;
    color: #525252;
}
.el-pref-glyph-checkbox-div {
    display: inline-block;
}
.el-pref-arrow-glyph {
    vertical-align: sub;
    padding-left: 10px;
}
.el-cl-pref-toggle-div{
    margin-top: 32px;
}
.el-sl-pref-glyph-inline{
    vertical-align: top;
}
.el-pref-card-body-info-div{
    min-height: 75px;
}