
.ltiInformationModal .ltiUrl {
    padding: 8px;
    background: #EBEBEB;
    border-radius: 6px;
    margin: 0px;
}

.ltiInformationModal .copyBtn {
    height: auto;
    padding: 8px 8px;
    letter-spacing: normal;
}