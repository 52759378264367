.easyLoginMainContainer{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    /* background: #ffd916; */
    min-height: 490px;
}
.easyLoginMainContainer{
    text-align: center;
}
.easyLoginSubContainerDiv{
    padding: 20px 0px;
}
.easyLoginSubContainerDiv h2{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    margin: 0px;
    color: #5a388a;
    padding: 10px 20px;
}
.easyLoginSubContainerDivText{
    font-size: 18px;
    color: #000000;
}
.easyLoginIconMainContainer{
    margin: auto !important;
    max-width: 800px;
    padding: 0px;
}
.easyLoginIconContainer{
    display: inline-block;
    padding: 0px 20px 40px 20px;
    vertical-align: top;
}
.easyLoginIconBtns{
    /* border: 5px solid #FFFFFF; */
    /* border: 5px solid #adadad;
    box-shadow: 0 9px 2px -2px #E1E1E1; */
    border: none;
    box-shadow: 7px 9px 2px -2px #c0c0c0;
    border-radius: 30px;
    width: 100px;
    height: 100px;
    margin-bottom: 10px
}
.easyLoginStudentName{
    max-width: 105px;
    word-break: break-word;
}
.easyLoginClassName {
    width: 210px;
    word-break: break-word;
}
.easyLoginIconBackBtnDiv{
    padding: 0px 0px 40px 0px;
}
.easyLoginIconBackBtn{
    border: 1px solid #E7C421;
    border-radius: 40px;
    width: 70px;
    height: 70px;
    background: #FCE85C;
}
.back-arrow-glyph{
    font-size: 40px;
    color: #5a388a;
}
.easyLoginAlert{
    /* background: #ffd916; */
    text-align: left;
    margin-top: 0px;
}
/* .easyLoginAlert .close,
.easyLoginAlert .close:focus{
    background: #ffd916;
} */
.easyLoginAlertMainDiv{
    padding-top: 15px;
}
.easyLoginErrorDiv{
    padding: 100px 0px;
}
.easyLoginErrorDiv h1{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    font-weight: bold;
    color: #5a388a;
    padding: 20px 0px;
    margin: 0px;
}
.easyLoginErrorDiv h2{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
}
.easyLoginErrorSubDiv{
    display: inline-table;
}
.easyLoginErrorSubDiv.error-txt{
    text-align: left;
    padding-left: 20px;
    max-width: 550px;
    vertical-align: middle;
}
.easyLoginErrorSubDiv img{
    height: 170px;
}
.easyLoginLinks{
    font-size: 26px !important;
}
.wrongPassword{
    border: 5px solid #EC1D25;
    box-shadow: 0 9px 2px -2px #EC1D25;
}
.el-login-page-back-btn {
    text-align: left;
}
.schoolEasyLogin-svg {
    cursor: pointer;
}
.schoolEasyLogin-svg-big-icons {
    border: none;
    box-shadow: 7px 9px 2px -2px silver;
    border-radius: 30px;
    background: silver;
    margin-bottom: 10px;
}
.el-password-lock-popup{
    padding: 30px;
    text-align: center;
}
.el-password-lock-popup h4 {
    color: #523084;
    margin: 0;
}
.el-password-lock-popup-body{
    padding: 40px 0;
}