#cleverAdminDetailsMainDiv{
	font-family: 'Museo Sans W01_500',arial;
	letter-spacing: 1px;
	padding: 20px;
	background: #EBEBEB;
}
#cleverAdminDetailsCloseDiv{
	height: 40px;
}
#cleverAdminDetailsCloseDiv a{
	display: inline-block;
	float: right;
}
#cleverAdminDetailsCloseImg{
	height: 15px;
}
#cleverAdminDetailsHeader{
	padding-top: 0px;
}
#subscriptionDropdownBtn{
	width: 300px;
}
#cleverAdminSchoolName{
	font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #523084;
}
.cleverAdminDetailsInnerDiv{
	padding: 20px 40px 0px 40px;
	font-size: 14px;
	font-weight: bold;
}
#cleverAdminSubscriptionCount{
	padding-top: 10px;
	font-size: 14px;
	font-weight: normal;
}
#cleverAdminDetailsTable{
	font-size: 14px;
	font-weight: normal;
	width: 100%;
	border-bottom: 1px solid #CCCCCC;
}
#cleverAdminDetailsTable .subscriptionList{
	background: #FFFFFF;
}

#cleverAdminDetailsTable .subscriptionListAlt{
	background: #EBEBEB;
}
#cleverAdminDetailsTable th{
	padding-bottom: 10px;
	vertical-align: top;
	background: #EBEBEB;
}
#cleverAdminDetailsTable th:nth-of-type(2){
	float: right;
}
#cleverAdminDetailsTable td{
	height: 40px;
	padding: 10px 20px;
	color: #000000;
}

#cleverAdminDetailsTable td:nth-of-type(1){
	word-wrap: break-word;
	border-left: 1px solid #CCCCCC;
}

#cleverAdminDetailsTable td:nth-of-type(3){
	text-align: right;
	padding-right: 20px;
	border-right: 1px solid #CCCCCC;
}
#teacherHeaderRow2{
	background: #FFFFFF;
}
#teacherHeaderRow2 td{
	font-weight: bold;
	border-top: 1px solid #CCCCCC;
}
#searchBoxDiv,#applicationDivCleverAdmin{
	display: inline-block;
}
#applicationDivCleverAdmin{
	float: right;
	margin-right: 40px;
}
#cleverAdminDetailsSearchDiv{
	padding-bottom: 10px;
}
#cleverAdminDetailsSubDiv{
	display: inline-block;
}