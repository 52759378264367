#settingsMainDiv{
    text-align: center;
}
.left-subnav-div{
    background: #EBEBEB;
    min-height: 550px;
    display: inline-block;
    width: 14%;
    float: left;
}
.subnav-content-grid{
    width: 80%;
    display: inline-block;
}
.subnav-content-div{
    display: grid;
    margin: 50px 0px 0px 0px;
}
.subnav-menu{
    list-style-type: none;
    padding: 10px;
}
.subnav-menu li{
    font-size: 14px;
    text-align: center;
    padding: 20px 0px;
}
.subnav-menu li a{
    color: #5a388a;
    text-decoration: none;
    letter-spacing: 2px;
}
.subnav-menu li a.active{
    text-decoration: underline;
}
.subnav-content-subdiv{
    padding: 20px;
}
.subnav-content-subdiv .el-btn-div{
    padding-top: 30px;
}
.editClassName-subdiv{
    padding: 20px;
}
#editClassNameInput{
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    padding: 5px 8px;
    width: 200px;
    font-weight: bold;
    outline: none;
    margin-left: 10px;
}
.editClassBtn{
    width: 150px;
}
.editClassAlert{
    text-align: left;
}
#classGradeSelectionDiv{
    display: inline-block;
    text-align: left;
}
#classGradeSelection-main-label{
    width: 200px;
    vertical-align: top;
}
.classdropdown{
    width: 250px;
    cursor: initial !important;
}
#easyLoginCheckbox{
    margin-top: 20px;
}
#easyLoginUrlDiv{
    padding-top: 20px;
}
#multipleGradesCheckboxDiv label{
	font-family:'Museo Sans W01_500',arial;
	font-weight: normal;
    display: inline;
}
.easyLoginInfoHeader{
    max-width: 900px;
    margin: auto;
}
.easyLoginToggleDiv {
    background: #ebebeb;
    max-width: 700px;
    border-radius: 6px;
    padding: 40px 20px 20px 20px;
    margin: 20px auto;
}
.easyLoginToggleDiv h4{
    font-size: 14px;
    padding-bottom: 10px;
    margin: 0px 0px 10px 0px;
}
.easyLoginToggleBtn{
    background: none;
    border: none;
}
.easyLoginToggleGlyph{
    color: #70D02C;
    width: 50px;
}
.easy-login-grey-background{
    display: inline-block;
    padding: 12px;
    background-color: #ACACAD;
    border: 2px solid #ACACAD;
    border-width: 2px 0px 2px 2px;
    color: #FFFFFF !important;
    border-radius: 5px 0px 0px 5px;
    font-size: 12px;
}
.easy-login-grey-border{
    display: inline-block;
    padding: 12px;
    border: 2px solid #ACACAD;
    border-radius: 0px 5px 5px 0px;
    color: #523084;
    font-size: 12px;
}
.easyLogin-inst-div{
    max-width: 900px;
    margin: 40px auto 0px auto;
}
.el-header-text{
    color: #523084
}
.el-info-text{
    color: grey;
    padding: 10px 0;
}
.el-info-sub-text{
    display: inline-block;
    padding: 10px 0px;
}
.el-info-sub-text ol{
    text-align: left;
}
.easyLogin-inst-header{
    margin-bottom: 20px;
}
.el-inst-sub-div{
    text-align: left;
    margin-top: 40px;
}
.el-inst-list{
    margin: 0px;
    padding: 0px 20px;
}
.el-inst-links,
.el-inst-links:hover,
.el-inst-links:focus{
    color: #337ab7;
    text-decoration: underline;
}
#el-inst-classroomQrcode-container-div, #el-sl-inst-onLms-container-div{
    border-bottom: 2px solid #CCCCCC;
    border-radius: 0px;
}
.classSettings-className{
    color: #523084;
}
div[aria-expanded=false] .glyphicon-menu-down{
    display: none;
}
div[aria-expanded=true] .glyphicon-menu-right{
    display: none;
}
.el-pref-settings-card {
    padding: 20px;
    max-width: 310px;
}
.el-pref-settings-btn {
    width: 40px !important;
    height: 40px !important;
}
.backBtn-left {
    text-align: left;
}
.el-header-info-text {
    color: grey;
    padding: 10px 0 10px 0;
}
.el-info-text-ol{
    display: inline-block;
}

.gradeInformation {
    width: 300px;
}

.addLeftMargin {
    margin-left: 50px;
}

.alignLeft {
    text-align: left;
}



@media (min-width: 1920px) {
    .left-subnav-div{
        width: 12%;
    }
}

@media (min-width: 2500px) {
    .left-subnav-div{
        width: 8%;
    }
}