.assign-grade-level-modal .modal-header,
.assign-grade-level-modal .modal-body,
.assign-grade-level-modal .modal-footer {
    padding-left: 38px;
    padding-right: 38px;
}

.assign-grade-level-modal .modal-header {
    padding-top: 38px;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown {
    text-align: center;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-button {
    background-color: #ffffff;
    border: solid 1px #979797;
    border-radius: 2px;
    display: block;
    height: 40px;
    width: 213px;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-button[disabled] {
    border-color: #979797;
    opacity: 0.43;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-menu {
    border: solid 1px #979797;
    border-radius: 2px;
    border-top: none;
    margin: 0;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0;
    width: 100%;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-menu li
{
    display: block;
    height: 40px;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-menu li a
{
    padding: 10px 12px;
}

.assign-grade-level-modal .modal-body.modal-body-container .grade-level-dropdown .dropdown .dropdown-menu li a:hover
{
    background: #523084;
    color: #FFFFFF;
}

.assign-grade-level-modal .modal-footer {
    padding-bottom: 38px;
}

.assign-grade-level-modal .modal-footer.modal-buttons-container .cancel-button {
    background: #fff;
    border: solid 1px #4c357f;
    color: #4c357f;
}

@media (max-width: 767px) {
    .assign-grade-level-modal .modal-footer.modal-buttons-container button.btn {
        margin: 10px auto;
    }
}