.modal {
    text-align: center;
    padding: 0!important;
}

.modal-content {
    width: 500px;
}

.modal::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.close {
    float: right;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

#show-password-modal{
    padding: 10px;
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: normal;
    height: 250px;
    border-radius: 5px;
}

.add-message-space {
    margin-top:40px;
}

.base-content-forgot-password {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #000000;
    text-align: center;

}

.display-password-message {
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: normal;
}

.sub-content-password {
    margin-top: 20px;
    letter-spacing: normal;
}

@media only screen and (max-width: 767px) {

    .modal-content {
        width: 100%;
    }

    #show-password-modal {
        height: 200px;
    }

}