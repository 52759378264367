.table-container {
    flex-direction: column;
    align-items: center;
    display: flex;
    border: 0 solid #ffffff;
    border-radius: 0;
    margin: 2em 0;
    overflow: hidden;
}

.header-title-text {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4c357f;
}

.header-title-container {
    padding: 1em;
    max-width: 619px;
}

.description-text {
    font-family: 'Museo Sans W01_300', arial;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.table-description-container {
    padding: 1em;
}

.search-students-panel, .add-students-panel {
    width: 100%;
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.search-table {
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 0 0 0;
    padding: 1em 0 1em 0;
    width: 70%;
    height: 268px;
}

.add-table {
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1em 0 0 0;
    padding: 1em 0 1em 0;
    width: 70%;
}

.add-row-container {
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1em 0 0 0;
    padding: 1em 0 1em 0;
    width: 70%;
    height: 40px;
}

.add-row-element {
    margin: 0;
    padding: 0 1% 0 1%;
}

.add-row-container-4cols > .add-row-element {
    width: 50%
}

.add-row-container-5cols > .add-row-element {
    width: 50%
}

.add-table-single-grade {
    height: 268px;
}

.add-table-multiple-grade {
    height: 328px;
}

.search-table-cell {
    max-width: 222px;
    height: 40px;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
}

.table-header-title-label {
    font-family: 'Museo Sans W01_700', arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.search-table-4cols > .search-table-cell {
    width: 50%;
}

.search-add-table-4cols > .search-table-cell {
    width: 50%;
}

.search-add-table-5cols > .search-table-cell {
    width: 50%;
}

.search-table-header label {
    margin: 0 0 0 20%;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.search-table th, .add-table th {
    background-color: #ebebeb;
    color: #000000;
    padding: 10px;
    font-family: 'Museo Sans W01_700', arial;
    font-weight: bold;
}

.search-table td, .add-table td {
    background-color: #ebebeb;
    color: #000000;
    text-align: center;
    vertical-align: middle;
}

.student-search-buttons-container, .student-add-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 438px;
    margin-bottom: 2em;
}

.search-results-panel {
    border-radius: 5px;
    background-color: #ebebeb;
    max-width: 1117px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-results-found {
    border-radius: 5px;
    width: 100%;
    max-width: 1117px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-results-container {
    padding: 0 2em 1em 2em;
    width: 100%;
}

.table-results {
    /* start here if search results to be converted to panels of students */
    border-collapse: separate;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    border: #979797 solid 1px;
    width: 100%;
    box-sizing: border-box;
    border-spacing: 0;
}

.table-results tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 165px; /* 4 rows visible */
}

.table-results td {
    color: #000000;
    padding: 10px;
    text-align: left;
    vertical-align: middle;
}

.table-results tbody, .table-results thead tr {
    /*display: block; !* necessary for overflow-y: scroll; *!*/
    /* ^ will mess with row and cell widths */
}

.table-results th {
    height: 68px;
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
    font-weight: 300;
    text-align: left;
}

.table-results tbody td, .table-results thead th {
    width: 18%;
}

.table-results tbody td:first-child, .table-results thead th:first-child {
    width: 10%; /* 18% first * 5 cols + 10% click to select/add */
}

.table-results tr:first-child th {
    background-color: #ffffff;
}

.table-results tr:nth-child(2n) td {
    background-color: #ebebeb;
}

.table-results tr:nth-child(2n+1) td {
    background-color: #ffffff;
}

.table-results tr:first-child th {
    border-bottom: #979797 solid 1px;
}

/** rounded table borders */
.table-results thead tr:first-child th:first-child {
    border-top-left-radius: 5px;
}

.table-results thead tr:first-child th:last-child {
    border-top-right-radius: 5px;
}

.table-results tbody :last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.table-results tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

.results-max-height {
    max-height: 573px;
}

.search-results-header {
    max-width: unset;
    /*text-align: left !important;*/
    /* ^ search results header was originally aligned left by design */
}

.search-results-header .table-description-container {
    padding-left: 0;
}

.student-results-buttons-container {
    text-align: center;
    padding-bottom: 1em;
}

.student-search-btn {
    cursor: pointer !important;
    margin-right: 25px;
    margin-bottom: 1em;
}

.add-student-and-search-btn {
    width: 215px;
}

.add-header-title-container {
    display: inherit;
}

.add-student-row-image {
    cursor: pointer;
    color: #523084;
    font-size: 30px !important;
}

.add-student-row-image.disabled {
    cursor: pointer;
    color: #a9a9a9;
    font-size: 30px !important;
}

.confirm-students-panel {
    padding: 0 2em 1em 2em;
    width: 100%;
}

.confirm-students-container {
    padding: 2em 2em 2em 2em;
    width: 100%;
}

.confirm-text {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4c357f;
}

.confirm-students-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 438px;
    margin-bottom: 4em;
}

@media all and (min-width: 767px) {

    .search-students-panel {
        max-width: 1117px;
        height: 224px;
    }

    .search-table {
        flex-direction: row;
        width: 100%;
        padding: 2em 6% 2em 6%;
        justify-content: space-evenly;
        height: 268px;
    }

    .add-students-panel {
        max-width: 1117px;
        height: unset;
    }

    .add-table {
        flex-direction: row;
        width: 100%;
        margin: 0 0 0 0;
        padding: 1em 6% 0 6%;
        justify-content: space-evenly;
        height: unset;
    }

    .add-row-container {
        flex-direction: row;
        width: 100%;
        margin: 0 0 0 0;
        padding: 1em 6% 0 6%;
        height: 40px;
    }

    .add-row-container.add-row-container-4cols {
        justify-content: space-evenly;
    }

    .add-row-container.add-row-container-5cols {
        justify-content: flex-start;
    }

    .add-row-element {
        max-width: 222px;
    }

    .add-row-container-4cols > .add-row-element {
        width: 25%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .add-row-container-5cols > .add-row-element {
        width: 22.5%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .add-row-container-4cols > .add-row-element:nth-child(5) {
        width: 10%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .add-row-container-5cols > .add-row-element:nth-child(6) {
        width: 10%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .add-table:first-child {
        margin-top: 2em;
    }

    .add-header-title-container {
        display: none;
    }

    .search-table-4cols > .search-table-cell {
        width: 25%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .search-add-table-4cols > .search-table-cell {
        width: 30%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .search-add-table-5cols > .search-table-cell {
        width: 22.5%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .search-add-table-5cols > .search-table-cell:nth-child(11),
    .search-add-table-5cols > .search-table-cell:nth-child(12) {
        width: 10%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .search-add-table-4cols > .search-table-cell:nth-child(11),
    .search-add-table-4cols > .search-table-cell:nth-child(12) {
        width: 10%;
        margin: 0;
        padding: 0 1% 0 1%;
    }

    .search-table-header label {
        margin: 0 0 0 10px;
    }

    .student-add-buttons-container {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .confirm-students-buttons-container {
        margin-top: 1em;
        margin-bottom: 4em;
    }

    .no-results-found {
        height: 250px !important
    }

    .student-results-buttons-container {
        text-align: center;
    }

    .student-search-btn {
        width: 130px;
    }

    .add-student-and-search-btn {
        width: 272px;
    }

}

.upload-header-title-container {
    padding: 2em 1.6em 1em 1.6em;
    width: 100%;
}

.upload-results-panel {
    border-radius: 5px;
    background-color: #ebebeb;
    max-width: 1117px;
    display: flex;
    flex-direction: column;
}

.upload-header-title-text {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4c357f;
}

.upload-description-text {
    font-family: 'Museo Sans W01_300', arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 0 2em 2em 2em;
}

.no-top-padding {
    padding-top: 0;
}

/* dropdown styling */

select.add-student-dropdown {
    padding: 0 20px 0 10px;
    height: 40px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #979797;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    background-image: url(../images/faticons_arrow-down-ffcu.png);
    background-repeat: no-repeat;
    background-color: #fff;
    -webkit-appearance: none;
    background-position-x: 96%;
    background-position-y: center;
    background-size: 16px 16px;
}

select.add-student-dropdown option {
    height: 40px;
    background-color: #ffffff;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

select.add-student-dropdown option:first-of-type {
    height: 40px;
    background-color: #ffffff;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
}

select.add-student-dropdown:invalid {
    color: #9b9b9b;
    opacity: 50%;
}

.add-student-dropdown-select-placeholder {
    color: #9b9b9b;
    opacity: 50%;
}

/* input styling */

input.add-student-input {
    padding: 0 0 0 10px;
    height: 40px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #979797;

    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    color: #000000;
}

input.add-student-input.no-tooltip-error + .tooltip {
    display: none;
}

input.add-student-input::placeholder {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    color: #9b9b9b;
}

/* Input Validation Styles */
.add-student-input.ng-invalid-duplicate-names,
.add-student-input.ng-invalid-duplicate-student-id,
.add-student-input.ng-invalid-forbidden-input {
    border: solid 2px #f5a623;
}

.add-student-input.section-duplicate,
.add-student-input.org-duplicate {
    border: solid 2px #f5a623!important;
}
.add-student-input.blank-name {
    border: solid 2px #f5a623!important;
}
.add-student-input.duplicate-student-id {
    border: solid 2px #f5a623!important;
}
.add-student-dropdown.grade-invalid {
    border: solid 2px #f5a623!important;
}
