.ltiErrorMainContainer{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    min-height: 490px;
}
.ltiErrorMainContainer{
    text-align: center;
}
.ltiErrorSubContainerDiv{
    padding: 20px 0px;
}
.ltiErrorSubContainerDiv h2{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    margin: 0px;
    color: #5a388a;
    padding: 10px 20px;
}
.ltiErrorDiv{
    padding: 100px 0px;
}
.ltiErrorDiv h1{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
    font-weight: bold;
    color: #5a388a;
    padding: 20px 0px;
    margin: 0px;
}
.ltiErrorDiv h2{
    font-family: 'Didact Gothic','Museo Sans W01_500',arial;
}
.ltiErrorSubDiv{
    display: inline-table;
}
.ltiErrorSubDiv.error-txt{
    text-align: left;
    padding-left: 20px;
    max-width: 550px;
    vertical-align: middle;
}
.ltiErrorSubDiv img{
    height: 170px;
}