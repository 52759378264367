#addStudentPopupHeader{
	font-family: 'Museo Sans W01_500',arial;
	padding: 20px;
}
#addStudentPopupHeader h3{
	text-align: center;
	font-size: 18px;
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
}
#addStudentPopupDiv{
	font-family: 'Museo Sans W01_500',arial;
	text-align: right;
}
#addStudentPopupDiv{
	padding: 0px 10px;
	margin: auto;
}
/*MB: Keeping this browser specific css in case there is a need to have that again  */
/* @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    #addStudentPopupDiv{
	padding: 0px 52px;
} 
}
@-moz-document url-prefix() {
    #addStudentPopupDiv{
	padding: 0px 52px;
}
}
 @media all and (-ms-high-contrast:none)
     {
     #addStudentPopupDiv{
	padding: 0px 52px;} IE10
     *::-ms-backdrop, #addStudentPopupDiv{
	padding: 0px 52px; IE11
     }
 } */
#addStudentPopupFooter{
	text-align: center;
	padding: 20px;
}
#addStudentPopupCancelBtn,
#addStudentPopupValidateBtn,
#addStudentPopupSaveBtn,
#addStudentPopupEditBtn
{
	width: 130px;
}
#csvImportLinkQef{
	padding: 0px 0px 20px 20px;
	font-size: 16px;
}
#csvImportLinkQef a{
	font-family: 'Museo Sans W01_500',arial;
	font-weight: bold;
	text-decoration: underline;
	color: #0c84e1;
}
#csvImportLinkQef a:hover{
	text-decoration: underline;
	color: #0c84e1;
}
.newStudentPopupError{
	text-align: center;
}
#qefUploadedStudentListHeader-xs{
	text-align: center;
	border: none;
	padding: 15px 0px;
}