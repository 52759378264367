#changePasswordDiv{
	padding: 35px 60px;
	color: #000000;
	font-family: 'Museo Sans W01_500',arial;
	letter-spacing: 1px;
}
#changePasswordHeader {
    text-align: center;
    margin: 0px 0px 10px 5px;
}
#changePasswordHeader h1{
    font-size: 20px;
    font-family: 'Museo Sans W01_700',arial;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0px;
}
.changePasswordSubSection{
	padding: 20px 0px 10px 0px;
}
.changePasswordSubSection .changePasswordInputDiv{
    display: inline-block;
}
.passwordInputBox {
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    background: #EBEBEB;
    padding: 5px 8px;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.passwordInputBox:focus{
	background: #FFFFFF;
}
.updatePasswordClassSubHeader{
    font-size: 14px;
    font-family: 'Museo Sans W01_700',arial;
    font-weight: bold;
    letter-spacing: 1px;
}
.errorMsg.accountForm.changePassword{
	padding: 0px;
}
#changePasswordFooter{
    text-align: right;
    padding: 20px 10px 0px 0px;
}
#changePasswordCancelBtn{
	margin-right: 10px;
}