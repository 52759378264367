.addStudent-sub-container {
    max-width: 75%;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}


.add-student-header-title-text {
    font-family: 'Museo Sans W01_700',arial;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4c357f;
}

.add-student-description-text {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-top:30px
}

.student-redirect-container {
    height: 172px;
    max-width: 55%;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.student-redirect-button {
    width: 240px;
    height: 40px;
    background-color: #4c357f;

}

.addMargin {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.addStudent-content {
    margin-top: 30px;
    margin-left: auto;
    max-width: 75%;
    margin-right: auto;;
}

.addStudent-content.pad-bottom {
    padding-bottom: 30px;
}

.addInBetweenSpace {
    margin-right: 50px;
}

.add-student-skip {
     letter-spacing: normal;
     padding-top: 16px;
 }

.add-student-skip .btn.btn-link {
    font-size: 16px;
    line-height: normal;
    color: #4c357f;
    padding: 0;
}

@media only screen  and (max-width: 320px)  {
    .student-redirect-button {
        white-space: normal;
        margin-bottom: 10px;
    }
}


/* Portrait phones and smaller */
@media only screen  and (min-width: 321px)  and ( max-width: 375px) {
    .add-students-button-container {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    .addStudent-content {
        font-size: 15px;
    }
    .student-redirect-button {
        white-space: normal;
        margin-bottom: 10px;
    }

}


/* Portrait phones and smaller */
@media only screen  and (min-width: 376px)  and ( max-width: 425px) {
    .add-students-button-container {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    .addStudent-content {
        font-size: 15px;
    }

    .student-redirect-button {
        white-space: normal;
        margin-bottom: 10px;
    }

}


/* Portrait phones and smaller */
@media only screen  and  (max-width: 767px) {
    .student-redirect-container {
        max-width: 80%;
    }

    .student-redirect-container {
        max-width: 95% !important;
    }
 }


@media only screen and (min-width: 768px) {
    .addStudent-sub-container {
        max-width: 75%;
    }

    .student-redirect-container {
        max-width: 90% !important;
    }
}


@media only screen and (min-width: 991px) {

    .student-redirect-container {
        max-width: 60% !important;
    }
}

@media only screen and (min-width: 1366px) {
    .student-redirect-container {
        max-width: 45% !important;
    }
}