#shapeSelectionDiv{
    font-family: 'Museo Sans W01_500',arial;
    padding: 10px 0px;
    text-align: center;
}
#shapeSelectionDiv h4{
    color: #523084;
    padding: 10px 0px;
}
.selectShape{
    border: 5px solid #0c84e1;
    box-shadow: 0 9px 2px -2px #0c84e1;
}
#shapeSelectionFooterDiv{
    padding: 20px;
    text-align: right;
}
#shapeSelectionCancelBtn{
    width: 150px;
}
#shapeSelectionSaveBtn{
    width: 150px;
}
.easyloginshapes-scrollable-div{
    /* background: #ffd916; */
    padding: 40px 0px 20px 0px;
}
