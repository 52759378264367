.no-schools-container {
    padding: 20px 0;
    text-align: center;
}

.no-schools-container .no-schools-error {
    display: inline-table;
    padding: 100px 0;
}

.no-schools-container .no-schools-error .no-schools-error-text {
    text-align: left;
    padding-left: 20px;
    max-width: 550px;
    vertical-align: middle;
}

.no-schools-container .no-schools-error .no-schools-error-text h1,
.no-schools-container .no-schools-error .no-schools-error-text h2 {
    font-family: 'Didact Gothic', 'Museo Sans W01_500', arial, sans-serif;
    margin: 0;
}

.no-schools-container .no-schools-error .no-schools-error-text h1 {
    color: #5a388a;
    font-weight: bold;
}

.no-schools-container .no-schools-error .no-schools-error-text h2 {
    font-weight: 100;
    padding: 10px 20px;
}
