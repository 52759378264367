.wrapper {
    width: 100%;
}

.subscription-sub-header {
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4c357f;
    font-family: 'Museo Sans W01_500',arial;
}

.break-layout {
     clear: both;
 }

.fullscreen {
    width: 100% !important;
}


.subnav-content-grid-subscriptions {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom :40px;
    float: left;
    width: 85%;
}

.subscription-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.subscription-cards.space-top{
    margin-top: 30px;
}

.submenubar-space {
    padding-left: 10px;
    padding-top : 30px;
}

.subscriptionLogoDiv{
    font-family: 'Museo Sans W01_500',arial;
    color: #000000;
    margin-top: 30px;
    background: #ebebeb;
    padding: 30px;
    border-radius: 6px;
}

.subscriptionLogoInnerDiv {
    flex-basis: 25%;
    -webkit-flex-basis: 25%;
    margin: 1%;
}

.subscriptionLogoInnerDiv{
    padding: 20px;
    /*margin: 20px 20px 20px 0px;*/
    text-align: center;
    border: 1px solid #CCCCCC;
    background: #FFFFFF;
    min-width: 215px;
	min-height: 220px;
}
.subscriptionAppInfo{
    padding-top: 10px;
    font-size: 11px;
    width: 180px;
    min-height: 40px;
}
#subscriptionHomeBtns{
	padding: 3px 35px;
 }
.subscriptionAppInfoQuantity{
    min-height: 40px;
}
.subscriptionAppImage{
    width: 180px;
    height: 200px;
}
.subscriptionAppInfo.subscriptionManageBtnDiv{
    min-height: 50px;
}
#downloadAccessCodeButton{
	padding-top: 20px;
    border-radius: 24.5px;
    background-color: var(--blueberry);
}

@media only screen and (max-width: 517px) {

    .btn-margin-small {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .subnav-content-grid-subscriptions {
        width: 100%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;
        margin: 5%;
    }
}

@media only screen and (min-width: 768px) {
    .subnav-content-grid-subscriptions {
        width: 77%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;
        margin: 3%;
    }
}


@media only screen and (min-width: 991px) {

    .subnav-content-grid-subscriptions {
        width: 82%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;
        margin: 5%;
    }
}


@media only screen and (min-width: 1024px) {
    .subnav-content-grid-subscriptions {
        width: 83%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 15%;
        -webkit-flex-basis: 15%;
        margin: 2%;
    }
}

@media only screen and (min-width: 1280px) {
    .subnav-content-grid-subscriptions {
        width: 86%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 15%;
        -webkit-flex-basis: 15%;
        margin: 3%;
    }
}

@media only screen and (min-width: 1366px) {
    .subnav-content-grid-subscriptions {
        width: 86%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;
        margin: 2%;
    }
}

@media only screen and (min-width: 1920px) {
    .subnav-content-grid-subscriptions {
        width: 88%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;
        margin: 2%;
    }
}

@media only screen and (min-width: 2560px) {
    .subnav-content-grid-subscriptions {
        width: 92%;
    }

    .subscriptionLogoInnerDiv {
        flex-basis: 10%;
        -webkit-flex-basis: 10%;

    }
}