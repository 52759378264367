.app-modal-window .modal-dialog {
    width: 700px;
}

.banner-content {
    padding-right: 20px;
    padding-left: 20px;
}

.banner-close {
    float: right;
    font-size: 25px;
    font-weight: 400;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 1 !important;
}

.banner-message-content {
    letter-spacing: 1.25px !important;
}

.header-top {
    padding-top: 10px;
    padding-right: 10px;
}

#show-announcement-modal{
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: 1px;
    border-radius: 5px;
}

.btn-learn-more {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: right;
    padding: 10px;
    width: 160px;
    text-align: center;
}

.link-learn {
    color: white;
}


.show-announcement-header {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #4c357f;
    margin-bottom: 20px;
    text-align: center;
}

.display-announcement-message {
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.show-announcement-products {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: flex-start;
    justify-content: center;
}

.new-product {
    text-align: center;
    margin-top: 3%;
    width: 100%;
}

.sub-header-bold {
    font-weight: bold;
}

.img-product {
    text-align:center;
}

.image-style {
    width: 135px;
    height: 92px;
    object-fit: contain;
}

.text-product {
    text-align: center;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.confirm-announcement-button {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .app-modal-window .modal-dialog {
        width: 97%;
    }

    .confirm-announcement-button {
        text-align: center;
    }
}