#show-message-modal{
    padding: 20px;
    font-family: 'Museo Sans W01_500',arial;
    letter-spacing: 1px;
    height: 280px;
    border-radius: 5px;
}
#confirm-message-buttons{
    padding-top: 10px;
    text-align: center;
}

.add-message-space {
    margin-top:40px;
}

.show-message-header{
    font-family: 'Museo Sans W01_500',arial;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #4c357f;
    margin-bottom: 20px;
    text-align: center;
}

.btn-confirm {
    width: 175px;
    height: 40px;
    border-radius: 24.5px;
    font-family: 'Museo Sans W01_500',arial;
}

.btn-cancel {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: center;
    color: #523084;
    background-color: #ffffff;
}

.btn-confirm-save {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    background-color: #4c357f;
}

.message-content {

}

.display-message {
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Museo Sans W01_300',arial;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

@media only screen and (max-width: 767px) {
    #show-message-modal {
        height: 400px;
    }

    .btn-confirm {
        margin-bottom: 10px;
    }

    .btn-confirm-save {
        margin-right: 10px;
    }
}