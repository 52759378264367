#createUpdateClass,#createUpdateClassConfirmModal{
	padding: 40px;
	color: #000000;
	font-family:'Museo Sans W01_500',arial;
	letter-spacing: 1px;
	max-width: 600px;
    min-width: 300px;
    margin: 40px auto;
    background: #EBEBEB;
    border-radius: 10px;
}
.createClassSubContainer{
    background: #EBEBEB;
    display: table-cell;
    padding: 20px;
    border-right: 2px solid #FFFFFF;
    vertical-align: top;
}
.createClassHeader{
    text-align: center;
    margin-bottom: 30px;
}
.createClassHeader h1{
	font-size: 20px;
	font-family: 'Museo Sans W01_700',arial;
	letter-spacing: 1.5px;
	margin: 0px 0px 15px 0px;
	word-break: break-word;
}
.createClassHeaderSubDiv{
    background: #ebebeb;
    padding: 10px;
    color: #0c84e1;
}
.createUpdateClassSubHeader{
	width: 32%;
	vertical-align: top;
}
#schoolNameInnerDIV,#cleverGradeDropdownDIV{ 
	font-size: 16px;
}
#classTitleInput{
    border: 1px solid #CCCCCC;
    padding: 5px 8px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    outline: none;
}
#classTitleInput:focus{
	background: #FFFFFF;
}
.createUpdateClassSubSection{
	padding-top: 40px;
}
.createUpdateClassSubSection .classinput{
	display: inline-block;
	width: 65%;
}
.createUpdateClassFooter{
	text-align: center;
	padding-top: 30px;
	clear: both;
}
#createupdateCancelButton,#cancelArchiveBtn{
	width: 30%;
}
#createupdateSubmitButton,#confirmArchiveBtn{
	width: 30%;
}
#cleverSchoolMessage{
	text-align: center;
	color: #4A92E7;
}
#classDisplayName{
	padding-top: 5px;
}
#schoolNameText{
    width: 65%;
    display: inline-block;
}

.btn-year-class {
	width: 65%;
}

#createClassSchoolDropdown{
	width: 100%;
}
#createUpdateClass .gradeSelectionDiv{
	display: inline-block;
	width: 100%;
}
#gradeSelectionText2{
	display: inline-block;
}
#createUpdateClass .classdropdown{
	width: 100%;
}
#gradeDropdown2{
	margin-top: 10px;
}
#multipleGradesCheckboxDiv{
	margin-top: 15px;
}
.createUpdateClassFooter2{
	text-align: center;
	padding-top: 30px;
}
#createUpdateClassConfirmModal{
	text-align: center;
}
#createUpdateClassConfirmModal h3{
	margin: 0px 0px 10px 0px;
	word-break: break-all;
}
.initialOnBoardingText .sdm-links {
	font-size: 14px;
}