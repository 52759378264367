#licenseConfirmationDiv{
	padding: 10px;
	font-family: 'Museo Sans W01_500',arial;
}
#licenseConfirmationHeader{
	padding-bottom: 20px;
}
#licenseConfirmationHeader h3{
	font-size: 24px;
	font-weight: bold;
	color: #523084;
	margin: 0px;
}
#licenseConfirmationContent{
	font-size: 18px;
	color: #000000;
}
#licenseConfirmationFooterText{
	font-size: 18px;
	padding-top: 10px;
}