.ltiConfirmationModal {
    padding: 24px;
}

.ltiConfirmationModal h1 {
    margin-top: 0px;
    padding-top: 0px;
}

.ltiConfirmationModal .ltiConfirmationMessage {
    margin: 30px 12px;
    padding: 8px 0px;
}