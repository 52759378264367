.import-google-assign-grades-modal-gradeLevel-td-edit.selectize-input {
    font-size: 14px;
}

.import-google-assign-grades-modal-gradeLevel-td-edit.selectize-input input {
    font-size: 14px;
}

.import-google-assign-grades-modal-gradeLevel-td-edit.selectize-dropdown {
    font-size: 14px;
}

.import-google-assign-grades-modal-gradeLevel-td-edit {
    outline: none;
    word-break: break-word;
    font-weight: 500;
    letter-spacing: 1px;
}

.import-google-assign-grades-modal-gradeLevel-td-edit .selectize-input {
    height: 40px !important;
    white-space: nowrap !important;
}

.import-google-assign-grades-modal-gradeLevel-td-edit.error {
    border-radius: 8px;
    height: 42px;
    color: #EC1D25 !important;
    border: 1px solid #EC1D25 !important;
}

.import-google-assign-grades-modal-gradeLevel-td-edit.validEntry {
    border-radius: 8px;
    height: 42px;
    color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}

.import-google-assign-grades-modal-gradeLevel-td-edit .selectize-input::after {
    right: 5px !important;
}

input.import-google-assign-grades-modal-gradeLevel-td-edit {
    opacity: 0.5;
    border-radius: 8px;
    height: 40px;
    width: 101px;
    background-color: white;
}

.import-google-assign-grades-modal-gradeLevel-td-edit {
    display: flex;
    width: 101px !important;
    height: 42px;
}

.import-google-assign-grades-modal-gradeLevel-td-edit.selectize-control .selectize-dropdown {
    /* relative will hide the drop down under the footer, fixed will show it after an offset equivalent to the overflow rows*/
    position: fixed;
    top: auto;
    left: auto;
    width: inherit;
}

.import-google-assign-grades-modal-td {
    height: 40px;
    word-break: break-word;
    text-align: left;
    padding: 5px;
}

input.import-google-assign-grades-modal-firstName-td-edit {
    height: 100%;
    border-radius: 8px;
    border: 1px solid #b8b8b8;
    outline: 0;
    color: #000;
    width: 110px;
    padding-left: 10px;
    word-break: break-word;
}

.google-assign-grade-levels-modal {
    width: 100% !important;
}

.google-assign-grade-levels-modal th {
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    padding-bottom: 5px;
}

.google-assign-grade-levels-modal td {
    height: 40px;
    word-break: break-word;
    text-align: left;
    padding: 5px;
}

.google-assign-grade-levels-modal-scroll-class {
    overflow-y: auto;
    overflow-x: hidden;
    height: 263px;
}

.google-assign-grade-levels-modal-table {
    width: 100%;
}

div.import-google-assign-grades-modal-firstName-td-edit {
    padding-left: 5px;
}

div.import-google-assign-grades-modal-lastName-td-edit {
    padding-left: 5px;
}

input.import-google-assign-grades-modal-lastName-td-edit {
    height: 100%;
    border-radius: 8px;
    border: 1px solid #b8b8b8;
    outline: 0;
    color: #000;
    width: 110px;
    padding-left: 10px;
    word-break: break-word;
}

input.import-google-assign-grades-modal-studentId-td-edit {
    height: 100%;
    border-radius: 8px;
    border: 1px solid #b8b8b8;
    outline: 0;
    color: #000;
    width: 110px;
    padding-left: 10px;
    word-break: break-word;
}

.import-google-assign-grades-modal-student-item-row:nth-child(odd) {
    background-color: #ebebeb;
}

.import-google-assign-grades-modal-student-item-row:nth-child(even) {
    background-color: #fff;
}

.google-assign-grade-levels-modal-student-list-header .modal-title {
    text-align: center;
    border: none;
    padding: 5px;
}

.modal-header.google-assign-grade-levels-modal-student-list-header {
    min-height: 17px;
    padding: 0 0 0 0;
    border-bottom: none;
}

.google-assign-grade-level-modal-student-list-description {
    font-family: 'Museo Sans W01_400',arial;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}
