.ltiSettingsContainer{
    max-width: 800px;
    padding: 0px;
    text-align: center;
    margin: 40px auto;
}
.ltiSettingsSubContainer h3{
    text-align: center;
}
.ltiSettingsSubContainer{
    padding: 0px 0px 20px 0px;
    font-size: 14px;
    margin-bottom: 10px;
}
.ltiSettingsSubContainer .ltiSettingsInfoTextLink{
    font-size: 14px;
    text-decoration: underline;
}
.ltiSettingsContainer h2{
    border-bottom: 1px solid #CCCCCC;
    padding: 12px 0px;
    margin-bottom: 12px;
}
.ltiSettingsDataContainer{
    padding: 30px;
    background: #EBEBEB;
    border-radius: 6px;
}

.ltiSettingsDataContainer > div {
    display: flex;
    width: 100%;
}

.ltiSettingsBtn{
    margin: 10px auto;
    min-width: 180px;
    padding: 6px 45px;
}

.ltiSettingsLinkBtn {
   display: inline;
   padding: 0;
}

@media (min-width: 768px) {
    .ltiSettingsBtn {
        margin: 0 16px;
        min-width: 0;
    }
}

.ltiSettingDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 4px 6px;
}

.ltiSettingDiv:last-child {
    margin-bottom: 0px;
}

.ltiSettingDiv .ltiSettingText {
    text-align: left;
}

.ltiSettingActions {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .ltiSettingActions {
        flex-direction: row;
        justify-content: center;
    }
}

.ltiSettingDiv .ltiSettingText.ltiSettingTitle {
    font-weight: bold;
    min-width: 80px;
    text-align: left;
}

@media (min-width: 768px) {
    .ltiSettingDiv .ltiSettingText.ltiSettingTitle {
        min-width: 180px;
    }
}

.ltiSettingDiv .ltiSettingText .btn.stdbtns {
    margin: -4px 0px 0px 10px;
}

.ltiHome .btn.copyBtn {
    height: auto;
    padding: 8px 8px;
    letter-spacing: normal;
}

.ltiAvailableAppsDiv .schoolProductsImageDiv {
    background: transparent;
    min-width: 62px;
    width: 62px;
    height: auto;
}

.ltiAvailableAppsDiv .schoolProductsImageDiv:hover {
    cursor: default;
}

.ltiAvailableAppsDiv .schoolProductsImageDiv .subscriptionAppImage {
    width: 45px;
    height: 50px;
}

.ltiAvailableAppsDiv .appActions {
    max-width: 52px;
}

.ltiAvailableAppsDiv .ltiAvailableAppsTable td {
    vertical-align: middle;
}

.ltiSettingDiv .ltiSettingText.ltiSettingValue {
    padding: 0 16px;
    width: 50%;
}

.ltiSettingDiv .ltiSettingText.ltiSettingValue.full-width {
    width: 100%;
    white-space: normal;
    word-break: break-word;
}
