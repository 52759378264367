.addNewStudents-firstclass{
    margin: 40px auto;
    max-width: 650px;
    background: #EBEBEB;
    padding: 0px 40px 40px 40px;
}
.addNewStudents-firstclass h4{
    margin: 0px;
    padding: 40px 0px 20px 0px;
    color: #523084;
}
.addNewStudents-btns{
    text-align: center;
}