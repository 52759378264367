#licenseAcceptDiv{
	padding: 20px;
	font-family: 'Museo Sans W01_500',arial;
}
#licenceAcceptHeader{
	font-size: 18px;
	color: #000000;
	padding: 20px 0px;
}
#userNameDiv h3{
	font-family: 'Museo Sans W01_700',arial;
	font-size: 24px;
	font-weight: bold;
	color: #523084;
	margin: 0px;
}
#licenseAcceptBtnDiv{
	padding: 20px 0px;
}
#termsPolicyIframe{
	border: 2px solid #CCCCCC;
}
#licenseAcceptBtn
{
	background: #EC1D25;
}
#orgName{
	color: #523084;
	font-family: 'Museo Sans W01_700',arial;
	font-weight: bold;
}
#licenseAgreementFooterText{
	font-size: 16px;
	padding-top: 10px;
}
#licenceAcceptCheckBoxDiv{
	padding-top: 20px;
}
.licenseLinks{
	color:#0c84e1;
}
.licenseLinks:hover{
	color:#06a6de;
}
#licenceAcceptCheckBoxDiv label{
	font-family: 'Museo Sans W01_500',arial;
	font-weight: normal;
	cursor: pointer;
	display: inline;
	letter-spacing: 1px;
}
#termsPolicyIframeDiv-xs{
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    width: 300px;
    height: 300px;
}