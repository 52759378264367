#teacherTableDiv {
    padding: 20px 30px;
    background-color: #EBEBEB;
    overflow-x: scroll;

    font-family: 'Museo Sans W01_500',arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
}

#teacherListTable {
    width:100%;
    font-family: 'Museo Sans W01_500',arial;
    border: 1px solid #CCC;
}

.lastClassTeacher {
    border-right: 1px solid #CCC;
}

#teacherListTable th{
    text-align: center;
    background: #FFFFFF;
}
.sdmTeacherHeader {
    padding-top: 10px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    padding-left: 20px !important;
}

.notGoogleStyle {
    padding-top: 10px;
    border-bottom: 1px solid #CCCCCC;
    padding-left: 20px !important;
}


.googleText {
    padding-left: 0px;
    font-family: 'Museo Sans W01_500', arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
}

#teacherListTable td{
    height: 40px;
    text-align: left;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px!important;
    font-weight: 500!important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px!important;
    color: #000!important;
}


.teacherDataClass {
    width: 45% !important;
    padding-left:20px;
}

.teacherRole {
    width: 25% !important;
}

#teacherListTable th:first-child{
    text-align: left;
    padding-left: 10px;
}

#teacherListTable .teacherList{
    background-color: #FFFFFF;
}
#teacherListTable .teacherListAlt{
    background-color: #EBEBEB;
}

.teacherDetailRow:nth-child(even) {
    background-color: #EBEBEB;
}

.teacherDetailRow:nth-child(odd) {
    background-color: #FFFFFF;
}

#subTitleTeacher {
    font-family: 'Museo Sans W01_500',arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
    padding-left: 10px;
}
.subTitleHeader {
    width: 102px;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}



@media only screen and (max-width: 600px) {
    #teacherListTable td {
        text-align: left;
        font-size: 12px!important;
    }

    .teacherDataClass {
        width: 60% !important;
    }


    .googleText {
        font-size: 12px;
    }

}
