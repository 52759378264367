#addAnotherStudentLink{
	cursor: pointer;
}

.addStudentButton {
    color: #523084;
	font-size: 30px !important;
}

.add-student-button-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.hrefNoLink:hover{
	color: #523084;
}

.newStudentGroupDiv{
   min-height: 50px;
}

#qefUploadedStudentList{
	font-family: 'Museo Sans W01_500',arial;
	padding: 20px 0px 10px 0px;
	width: 100%;
	max-height: 600px;
}

#qefUploadedStudentListHeader{
	text-align: center;
	border: none;
}
#qefUploadedStudentListHeader h3{
	font-weight: bold;
	font-size: 20px;
}
#qefUploadedStudentListTableDiv{
	max-height: 300px;
	overflow-y: auto;
}
.qefUploadedStudentListTable th{
    padding: 10px !important;
    text-align: center !important;
    border: 1px solid #CCCCCC;
}
.qefUploadedStudentListTable td{
	padding: 10px !important;
	text-align: center !important;
	border: 1px solid #CCCCCC;
}
#confirmCancelDiv1,#confirmCancelDiv2{
	text-align: center;
	padding: 20px 0px 10px 0px;
}