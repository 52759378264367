
.calendar-checkbox-input-label-container {
    display: inline-block;
    width: 78%;
}

.calendar-checkbox-container {
    display: inline;
    width: 14px;
    padding-right: 10px;
    position: relative;
}

.calendar-checkbox-container .calendar-checkbox-input {
    text-decoration: none;
    margin: 0;
    -ms-transform: scale(.1); /* IE */
    -moz-transform: scale(.1); /* FF */
    -webkit-transform: scale(.1); /* Safari and Chrome */
    -o-transform: scale(.1); /* Opera */
    transform: scale(.1);
}

.calendar-checkbox-container .calendar-checkbox-input-display {
    cursor: pointer;
    position: absolute;
    max-width: unset;
    top: 0;
    left: 0;

    width: 14px;
    height: 13px;
    border-radius: 2px;
    border: solid 1.5px #979797;
    background: #ffffff;
}

.calendar-checkbox-input-display:hover {
    border: solid 1.5px #3d98f5;
    background: #3d98f5;
}

.calendar-checkbox-container .calendar-checkbox-input-display:after {
    transform: rotate(-45deg);
    opacity: 0.02;
    content: '';
    position: absolute;
    top: 1.4px;
    left: 1.3px;

    width: 9px;
    height: 5px;
    background: transparent;
    border: 2.1px solid #fff;

    border-top: none;
    border-right: none;
}

.calendar-checkbox-container .calendar-checkbox-input-display:hover::after {
    opacity: 1;
}

.calendar-checkbox-container input[type=checkbox]:checked + .calendar-checkbox-input-display:after {
    opacity: 1;
}

.calendar-checkbox-container input[type=checkbox]:checked + .calendar-checkbox-input-display {
    background: #3d98f5;
    border: solid 1.5px #3d98f5;
}

.generic-message-label {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.generic-message {
    width: 98%;
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.sub-header {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4c357f;
    margin-bottom: 20px;
}

.add-school-year {
    color: #523084;
    font-size: 30px !important;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.wrapper-calendar {
    font-family: 'Museo Sans W01_500',arial;
    color: #000000;
    margin-top: 30px;
    background: #ebebeb;
    padding: 30px;
    border-radius: 6px;
    clear: both;
}

.school-calendar-table {
    padding: 32px 0;
    background-color: #EBEBEB;

    font-family: 'Museo Sans W01_500',arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
}

.calendar-list-table {
    width:100%;
    font-family: 'Museo Sans W01_500',arial;
    border: 1px solid #CCC;
}

.sdmCalendarHeader {
    padding-top: 10px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    padding-left: 20px !important;
}

.school-calendar-table td {
    height: 40px;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px!important;
    color: #000!important;
}

.calendar-school-year-name-col {
    width: 30%;
    text-align: left;
    padding-left: 18px;
}

.calendar-start-date-col, .Calendar-end-date-col {
    width: 25%;
    text-align: left;
    padding-left: 18px;
}

.calendar-start-date-edit {
    width: 25%;
    padding-left: 10px;
}

.last-calendar-col {
    width: 10%;
    padding-left: 5px;
}

.last-calendar-col-edit {
    width: 17%;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
}

.show-disabled {
    color: grey;
    pointer-events: none;
}

.disable-event-till-save {
    pointer-events: none;
}

.school-calendar-table .calendar-tbl-head {
    border-bottom: 1px solid #CCCCCC;
    padding: 10px 0 10px 18px;
    text-align: left;
    background: #FFFFFF;
    font-family: 'Museo Sans W01_500',arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.calendar-list-table {
    border-radius: 5px;
    border-collapse: unset;
}

.school-calendar-table tr th {
    padding-top:5px;
}

.school-calendar-table tr th:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.school-calendar-table tr th:last-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.school-calendar-table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.school-calendar-table tr:last-child td:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.school-calendar-table th:first-child {
    text-align: left;
}

.school-calendar-table th:last-child {
    text-align: left;
}

.school-calendar-table .calendar-list {
    background-color: #FFFFFF;
}
.school-calendar-table .calendar-list-alt {
    background-color: #EBEBEB;
}

.calendar-detail-row:nth-child(even) {
    background-color: #EBEBEB;
}

.calendar-detail-row:nth-child(odd) {
    background-color: #FFFFFF;
}

.calendar-title-font {
    font-family: 'Museo Sans W01_500',arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #000000;
}

.sub-title-header {
    font-family: 'Museo Sans W01_500',arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}


.school-year-subscriptions {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    width: 85%;
}

.input-class {
    height: 40px;
    width: 100%;
    background-color: white !important;
}

.addspace-calender {
    margin-bottom: 10px;
    margin-top: 10px;
}

.add-space-checkbox {
    margin-top: 20px;
}

.cancel-padding {
    margin-left: 10px;
}

.warning-alert{
    border: 2px solid #f5a623;
    border-radius: 6px;
    background: #FFFFFF;
    margin: 15px 15px 10px 15px;
    padding: 0px;
    word-break: break-word;
}

.alert-warning-div {
    background: #f5a623;
    color: #FFFFFF;
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}


.alert-warning-text-div{
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
}


@media only screen and (max-width: 600px) {
    .school-calendar-table td {
        text-align: left;
        font-size: 12px!important;
    }

    .calendar-school-year-name-col {
        width: 55% !important;
        padding-left: 2%;
    }

    .calendar-start-date-col, .Calendar-end-date-col {
        width: 13%;
        padding-left: 2%;
        text-align: left;
    }

    .last-calendar-col {
        width: 11%;
        padding-left: 2%;
    }

    .school-calendar-table th {
        padding-left: 2%;
    }

    .school-calendar-table th:first-child {
        padding-left: 2%;
    }

}

@media only screen and (max-width: 767px) {
    .school-year-subscriptions {
        width: 100%;
    }

    .input-class {
        font-size: 12px;
    }
}

@media only screen and (min-width: 768px) {
    .school-year-subscriptions {
        width: 77%;
    }

    .input-class {
        font-size: 13px;
    }
}


@media only screen and (min-width: 991px) {

    .school-year-subscriptions {
        width: 82%;
    }

    .input-class {
        font-size: 14px;
    }
}


@media only screen and (min-width: 1024px) {
    .school-year-subscriptions {
        width: 83%;
    }
}

@media only screen and (min-width: 1280px) {
    .school-year-subscriptions {
        width: 86%;
    }
}

@media only screen and (min-width: 1366px) {
    .school-year-subscriptions {
        width: 86%;
    }
}

@media only screen and (min-width: 1920px) {
    .school-year-subscriptions {
        width: 88%;
    }
}

@media only screen and (min-width: 2560px) {
    .school-year-subscriptions {
        width: 92%;
    }
}