#newStudentInnerTable tr > td:nth-of-type(1){
	text-align: right;
}
#newStudentInnerTable tr > td:nth-of-type(2){
	text-align: left;
}
#newStudentInnerTable tr > td:nth-of-type(3){
	text-align: left;
}
.NewStudentTableTDGeneric{
    padding: 5px;
}

.NewStudentTableTDGeneric input{
	border: 1px solid #CCCCCC;
	background: #EBEBEB;
	padding: 5px;
	color: #000000;
	width: 160px;
	outline: none;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.NewStudentTableTDGeneric input:focus{
	background: #FFFFFF;
}
.qefGradeDropdown{
	width: 160px;
	background: #EBEBEB;
	padding: 5px;
}
.dropdown.open .qefGradeDropdown{
	background: #FFFFFF;
}
.qefGradeDropdown:focus{
	background: #FFFFFF;
}
.removeStudentLink{
	font-weight: bold;
    text-decoration: underline;
    color: #0c84e1;
    outline: none;
}
.removeStudentLink:focus,.removeStudentLink:hover{
	font-weight: bold;
    text-decoration: underline;
    color: #EC1D25;
    outline: none;
}
@-moz-document url-prefix() {
    .addStudentDropdownContent{
       width: 98% !important;
    }
}