#reportingFilterHeaderDiv,#reportingMainDiv,#userRolesDiv{
    margin-bottom: 10px;
    background: #EBEBEB;
    width: 100%;
    padding: 20px 30px;
}

#reportingSchoolName{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #523084;
}
#reportingClassDropdownbtn{
	width: 250px;
}
.reportingDropdownOptions{
    letter-spacing: 1px;
    width: 250px;
    margin: 0!important;
}
.reportingFiltersDiv{
	margin-top: 10px;
}
.reportingFiltersDiv label{
	width: 80px;
}
.reportingDates{
    width: 210px !important;
    border-radius: 0px !important;
}
.calendar-glyph{
	 border-radius: 0px !important;
}
.reportingFilterInnerDiv{
	display: inline-block;
	padding-right: 20px;
}
#reportingTable{
	width: 100%;
    border: 1px solid #CCC;
}
#reportingTable th{
    border-bottom: 1px solid #CCCCCC;
    padding: 20px 0px 5px 0px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #CCC;
}
#reportingTable td{
    height: 40px;
    text-align: center;
    border: 1px solid #CCC;
}
#reportingTable .reportingList{
    background-color: #FFFFFF;
}
#reportingTable .reportingListAlt{
    background-color: #EBEBEB;
}
#changeFilterBtn{
	outline: none;
	border: none;
}