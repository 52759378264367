#csvPopupHeader{
	border: none;
	text-align: center;
	padding: 0px;
}
#csvPopupFooter{
	border: none;
	padding: 0px;
}

#templateImage{
	clear: both;
	display: inline-block;
	padding-left: 50px;
}

#importingCSV{
	text-align: center;
	margin: 30px 0px 10px 0px;
}

#downloadSample{
	text-align: center;
	margin: 10px 0px 5px 0px;
}


label#uploadButton input[type='file']{
	position: fixed;
	top: -1000px;
}
#uploadButton{
    vertical-align: middle;
    margin-top: 5px;
    padding: 12px 30px;
}
#uploadButton:hover {
   cursor: pointer;
}

#csvCloseButton{
	background: none;
	border: none;
	outline: none;
	margin: -10px -10px 0px 0px;
	padding: 0px;
	display: inline-block;
	float: right;
}

#csvPopupTitle {
	display: block;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	padding: 10px 10px 20px 0px;
}
#uploadedStudentList{
	padding: 30px;
	width: 100%;
	max-height: 600px;
}

#uploadedStudentList h3{
	font-weight: bold;
}

#csvTable{
	max-height: 300px;
	overflow-y: auto;

}
#importCSVTable {
    width: 75%;
    background-color : #ffffff;
    margin: 0px auto;
}

#importCSVTable .tdWidth {
    width:20%;
}
#importCSVTable .studentList{
    background-color : #ffffff;
}
#importCSVTable .studentListAlt{
    background-color: #EBEBEB;
}
.studentinfo:hover{
    cursor: pointer;
}
#importCSVTable th{
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
}
#importCSVTable td{
    height: 40px;
    word-break: break-word;
    text-align: left;
    padding: 5px;
}

#importCSVTable td.name{
    padding-right: 10px;
}

#importCSVTable .studentList > .editErrors{
	background-color : #ffffff;
}
#importCSVTable .rollOverText > .editErrors{
	display: none;
}
#importCSVTable tr:hover .rollOverText .editErrors{
	display: block;
}

#importCSVTable th{
	width:33%;
	padding-left:10px;
}


#importCSVTable .studentList.editErrors:hover{
	background-color: #d5e3fc;
}

#importCSVTable .studentListAlt.editErrors:hover{
	background-color: #d5e3fc;
}
.error{
	border: 1px solid  #EC1D25 !important;
    color:  #EC1D25 !important;
}

.error input[type='text'],
.error input[type='number'] {
	border: 1px solid  #EC1D25 !important;
	color:  #EC1D25 !important;
}

.error select{
	border: 1px solid  #EC1D25 !important;
    color:  #EC1D25 !important;
}

.duplicateError{
	background-color: #ff987e  !important;
}
.duplicateError > td.error{
	background-color: #ff987e !important;
}

.duplicateError input[type='text'],
.duplicateError input[type='number'] {
	background-color: #ff987e !important;
}

.editableDiv, .editableDivPopup{
	border: none;
	outline: none;
	white-space: pre-wrap;
	word-wrap: break-word;
	padding-left:10px;
}
.editableDivPopup{
	width: 115px;
}
.error.validField{
	color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}
.error.validField input[type='text'],
.error.validField input[type='number'] {
	color: #5cb85c !important;
	border: 1px solid #5cb85c !important;
}
.error.validField select{
	color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}
.editErrors{
	height: 100%;
	border: 1px solid #ccc;
	outline: none;
	color: black;
	width: 110px;
	padding-left: 10px;
	word-break: break-word;
}

.tooltip > .tooltip-inner{
	background: grey;
}

.tooltip > .tooltip-arrow{
	 border-bottom-color:grey !important;
	 border-top-color: grey !important;
}

.editErrorsSelect{
	height: 100%;
	border: 1px solid #ccc;
	outline: none;
	color: black;
	width: 130px;
	word-break: break-word;
	border-radius: 0px;
    -webkit-border-radius: 0px;
}
.studentList .editErrorsSelect{
	background-color: #FFFFFF;
}
.studentListAlt .editErrorsSelect{
	background-color: #EBEBEB;
}
.uploadedStudentListHeader{
	text-align: center;
	border: none;
	padding: 5px;
}
#uploadedStudentListFooter{
	text-align: right;
	border: none;
	font-weight: bold;
}
.note {
	font-size: 14px;
	font-weight: bold;
	display: inline-block;
	vertical-align: middle;
	padding-left: 20px;
}
#noteSubText1{
	display: inline-block;
	font-size: 11px;
	margin-bottom: 5px;
}
#noteSubText2{
	display: inline-block;
	margin-bottom: 20px;
	line-height: 17px;
}
#noteSubText3{
	display: inline-block;
	line-height: 17px;
}
#importCSVMainDiv{
	font-family: 'Museo Sans W01_500',arial;
}
#fileSubmit{
	display: inline-block;
}
#sampleDownloadDiv{
	display: inline-block;
}
#sampleDownloadBtn{
	padding: 3px 20px;
	font-size: 10px;
	letter-spacing: 2px;
	background: #FFFFFF;
	color: #523084;
	border: 1px solid #523084;
	height: auto;
}
#csvPopupCancelBtn,#editPopupCancelBtn,#confirmPopupCancelBtn,#csvPopupEditCancelBtn{
	font-size: 11px;
	background: #FFFFFF;
    color: #523084;
    border: 1px solid #523084;
    width: 130px;
    margin-right: 10px;
    letter-spacing: 1px;
}
#csvPopupValidateBtn,
#csvPopupSaveBtn,#confirmPopupCloseBtn,#csvPopupEditValidateBtn
{
	font-size: 11px;
    width: 130px;
    letter-spacing: 1px;
}
.nonEditableGrade{
	display: inline-block;
	width: 130px;
	padding-left: 10px;
}
.nonEditableGrade:hover{
	cursor: default;
}
#csvConfirmClosePopup{
	padding: 20px;
	text-align: center;
}
#csvConfirmClosePopupBtnsDiv{
	padding: 10px;
}
#importcsvButton{
	letter-spacing: 2px;
}