.add-new-class-panel {
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    width:95%;
    margin: auto;
}

.add-new-class-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.addclass-sub-container {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 60%;
    margin:auto;
}

.addclass-redirect-container {
    width: 60%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.add-class-header-title-text {
    font-family: 'Museo Sans W01_700',arial;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4c357f;
}

.add-class-description-text {
    font-family: 'Museo Sans W01_300',arial;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-top:30px
}