#schoolProductsDiv {
    padding: 10px 0px;
    font-family: 'Museo Sans W01_500', arial;
    color: #000000;
}

.schoolProductsInnerDiv {
    padding: 20px;
    margin: 20px 20px 20px 0px;
    text-align: center;
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    display: inline-block;
    vertical-align: top;
    background: #FFFFFF;
}

.productInfo {
    padding-top: 10px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000000;
    width: 180px;
    min-height: 45px;
}
.appName{
    display: inline-block;
}
.productAppsDiv {
    margin-top: 30px;
    background: #EBEBEB;
    padding: 30px;
    border-radius: 6px;
}

#productsOrgTypeAhead {
    padding-top: 10px;
}

.selectSchoolSubDiv {
    padding: 20px 0px;
}
.selectSchoolSubDiv h4{
    color: #523084;
    margin: 0px;
    font-size: 22px;
}
.selectSchoolSubDiv2 {
    padding: 30px 0px 0px 0px;
    font-size: 14px;
}

.schoolInfoDiv label,
#searchSchoolByZipDiv label {
   display: block;
   margin-bottom: 10px;
}

#selectSchoolDropdownBtn,
#selectSchoolByZipDropdownBtn {
    width: 300px;
}

#selectSchoolDropdownOptions,
#selectSchoolByZipDropdownOptions {
    width: 100%!important;
    margin: 0!important;
}
#selectSchoolConfirmChkBoxDiv{
    text-align: left;
    margin: 20px 0px 0px 0px;
    clear: both;
    padding: 50px 0px 0px 0px;
}
#selectSchoolConfirmChkBoxDiv label {
    font-family: 'Museo Sans W01_500', arial;
    font-weight: normal;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 1px;
    max-width: 90%;
    vertical-align: top;
}
#selectSchoolConfirmChkBoxDiv .sdm-links{
    font-size: 14px;
    color: #523084;
}

#selectSchoolMainDiv {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #282829;
    max-width: 800px;
    margin: auto;
}

#searchSchoolByZip-input {
    width: 85%;
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
}

#searchSchoolByZipBtn {
    height: 40px;
    vertical-align: bottom;
    background: #523084;
    border: none;
    width: 15%;
    outline: none;
    margin-left: -5px;
    border-radius: 0px 7px 7px 0px;
}

.search-glyph {
    color: #FFF;
}

.cm-logo {
    text-align: center;
}

.cm-dropdown {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.selectSingleSchoolDiv {
    font-weight: bold;
    width: auto !important;
    color: #523084;
    display: inline-block;
}

.informationContainer {
    color: #523084;
    font-size: 14px;
    padding: 10px 25px;
    border: 2px solid #CCCCCC; 
    border-radius: 10px;
    margin: 10px;
}

.infoBoxCloseIconDiv {
    text-align: right !important;
    margin: 5px -3px;
    color: black !important;
    margin-bottom: 5px;
}
.clickableIcon {
    cursor: pointer;
}
.schoolNotInListLinkDiv{
    padding-top: 10px;
}
.selectSchoolSubDivInline{
    display: table-cell;
    text-align: left;
    background: #ebebeb;
    height: 100%;
    padding: 30px;
    border-radius: 10px;
    vertical-align: top;
}
.otherSchoolLinkDiv{
    padding: 10px 0px 0px 0px;
}
.otherSchoolLink,
.otherSchoolLink:hover,
.otherSchoolLink:focus{
    font-size: 14px;
}

#licenseAgreementDiv{
    max-width: 700px;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: left;
    margin: auto;
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 20px;
}
.selectSchoolSubDiv3{
    padding: 20px 0px 0px 0px;
}
.accessCodeInfoTextLink,
.accessCodeInfoTextLink:hover,
.accessCodeInfoTextLink:focus{
    font-size: 12px;
    color: #523084;
    text-decoration: none;
}
.manageRosterBtnDiv{
    padding: 20px 0px;
}
.schoolProductsImageDiv{
    height: 200px;
    min-width: 180px;
    background: #FFFFFF;
}
.schoolProductsImageDiv:hover{
    cursor: pointer;
    cursor: hand;
}
.activateAccessCodeFormDiv{
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    color: #523084;
}
.accessCodeFormMainDiv{
    text-align: center;
}
.accessCodeFormMainDiv h3{
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}
.accessCodeFormSubDiv{
    display: inline-block;
    margin-right: 10px;
}
#accessCodeForm{
    margin: 0px;
}
#accessCodeForm input{
    border: 2px solid #fee94e;
    background: #FFFFFF;
    width: 180px;
    outline: none;
    padding: 10px;
    color: #000000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
#accessCodeForm input:focus{
    background: #FFFFFF;
}
/* #accessCodeButton{
    margin: 0px;
} */
.productLaunchBtnDiv{
    margin-top: 9px;
}
.selectSchoolContainerDiv{
    text-align: center;
    display: table;
    border-collapse: separate;
    border-spacing: 15px;
    width: 100%;
}
.selectSchoolLogoDiv{
    width: 30%;
}
.selectSchoolBtnDiv{
    padding: 20px 0px 0px 0px;
    text-align: center;
}
.searchSchoolByZipCancelBtn{
    padding-top: 100px;
    text-align: right;
}
.studentAccessMainDiv{
    text-align: center;
}
.studentAccessMainDiv h3{
    text-align: center;
}
.student-access-text-div{
    display: inline-block;
    max-width: 180px;
    padding: 20px 0;
    color: #808080;
    text-align: center;
    height: 190px;
}
.student-access-text-div-mag{
    height: auto;
}
.student-access-text-div:hover{
    cursor: default;
}
.cmStudentAccessPass{
    padding: 5px;
    margin-bottom: 20px;
    text-align: center;
}
.cmStudentAccessPassHeader{
    padding: 0px 0px 10px 0px;
}
.accessCodeNoEntitlementsDiv{
    max-width: 500px;
    padding: 0px;
    text-align: center;
    margin: 40px auto;
}
.accessCodeNoEntitlementsDiv h3{
    text-align: center;
}
.accessCodeNoEntitlementsSubDiv{
    padding: 0px 0px 20px 0px;
    font-size: 14px;
}
.accessCodeNoEntitlementsSubDiv .accessCodeInfoTextLink{
    font-size: 14px;
    text-decoration: underline;
}
.noEntitlementsAccessCodeFormDiv{
    padding: 30px;
    background: #EBEBEB;
    border-radius: 6px;
}
.accessCodeNoEntitlementsSubDiv input{
    width: 100%;
    padding: 10px;
}
.accessCodeBtn{
    padding: 6px 45px;
}
.accessCodeFormTitle{
    padding-bottom: 10px;
    font-size: 14px;
}
.edit-classpasscode{
    border: 3px solid #fee94e !important;
}
.schoolName{
    padding-top: 15px !important;
}
.applicationImage{
    width: 180px;
    height: 200px;
}

.view-lti-information {
    padding: 0;
}