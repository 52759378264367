.confirm-remove-student-modal .modal-footer.modal-buttons-container .cancel-button {
    background: #fff;
    border: solid 1px #4c357f;
    color: #4c357f;
}

.confirm-remove-student-modal .modal-header,
.confirm-remove-student-modal .modal-body,
.confirm-remove-student-modal .modal-footer {
    padding-left: 38px;
    padding-right: 38px;
}

.confirm-remove-student-modal .modal-header {
    padding-top: 38px;
}

.confirm-remove-student-modal .modal-body {
    text-align: center;
}

.confirm-remove-student-modal .modal-footer {
    padding-bottom: 38px;
}

@media (max-width: 767px) {
    .confirm-remove-student-modal .modal-footer.modal-buttons-container button.btn {
        margin: 10px auto;
    }
}