#studentInfoMainDiv{
    padding: 10px 20px 40px 20px;
    margin: auto;
    text-align: center;
    max-width: 550px;
    min-width: 300px;
    color: #000000;
}
#studentInfoMainHeader{
    color: #523084;
}
.studentInfoUpdateLabels{
    width: 48%;
    text-align: left;
}
.studentInfoUpdateSection{
    padding-top: 20px;
}
.studentInfoUpdateSection .studentInfoInputDiv{
    display: inline-block;
    width: 50%;
}
.studentInfoInput{
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    padding: 5px 8px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    outline: none;
}
.studentInfoInput:focus{
    background: #FFFFFF;
}
.studentInfoInput.duplicate-names  {
     border: solid 2px #f5a623!important;
 }

.studentInfoInput.invalid-password {
    border: solid 2px #f5a623!important;
}

.studentInfoGradeDropdownbtn{
    width: 100% !important;
}
.studentInfoGradeDropdown-content{
    width: 100%;
}
#studentInfoUsername,#studentInfoGradeLabel,.studentNonEditableInfo{
    text-align: left;
    font-weight: bold;
}
#studentInfoEasyLoginMainDiv{
    background: #EBEBEB;
    padding: 15px;
    margin-top: 20px;
    text-align: center;
}
#studentInfoEasyLoginMainDiv h4{
    font-size: 14px;
    margin: 10px 0px;
}
#studentInfoPopupFooterDiv1{
    padding: 20px;
    text-align: center;
}
#studentInfoCancelbtn,#studentInfoSavebtn{
    width: 150px;
}
#studentInfoPopupFooterDiv2{
    padding: 0px 20px;
    text-align: center;
}
#deactivateStudentFooterTxt{
    font-size: 12px;
    padding-top: 15px;
}
.backBtnDiv{
    padding: 40px 0px 0px 20px;
}
.studentInfoEasyLoginContainer{
    padding: 0px;
}
.studentInfoEasyLoginContainer label{
    display: block;
    margin-bottom: 10px;
}
.studentInfoEasyLoginIconBtns{
    width: 70px;
    height: 70px;
    border-radius: 25px;
}
.studentInfoShapeDescription{
    font-size: 12px;
    text-align: center;
    max-width: 90px;
    margin: auto;
}
.studentInfoEditGlyphDiv{
    padding: 54px 0px;
    border: none;
    background: none
}
.studentInfoEasyLoginSubDiv{
    display: inline-block;
    padding: 20px 30px 0px 30px;
}