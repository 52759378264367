#instructions{
	font-family: 'Museo Sans W01_500',arial;
	font-size: 16px;
    letter-spacing: normal;
}
#instructions h1{
	font-size: 16px;
	font-weight: bold;
}
#instructions h2{
	font-size: 16px;
	font-weight: bold;
}
.colorText{
	color: #0082D6;
}
#printbtns{
	text-align: center;
}
.btnprint{
	margin-top: 20px;
	letter-spacing: 2px;
}
.studentPrintInfo{
	padding: 0px 30px;
    letter-spacing: normal;
}
#printInstructionsHeader{
	padding: 0px 20px;
}
.printbtnimg{
	padding-right: 5px;
}
#printListTable{
	width: 100%;
	font-size: 16px;
}
#printListTable th{
	border-bottom: 1px solid #CCCCCC;
	height: 40px;
	margin-bottom: 10px;
}
#printListTable td{
	height: 40px;
}
#printListforTeacher{
	margin: 0px 20px;
}
#printHeader{
	text-align: center;
	padding-bottom: 20px;
}
#printHeader h3{
	font-family: 'Museo Sans W01_700',arial;
	font-size: 20px;
	color: #000000;
	margin: 0px;
}

#printCloseButton{
	background: none;
    border: none;
    outline: none;
    display: block;
    float: right;
    margin: -15px -25px 0px 0px;
    padding: 0px;
}
#printasFlyer,#printasTearOff,#printList{
	display: inline-block;
}
#printDiv{
	padding: 40px;
	font-family: 'Museo Sans W01_500',arial;
	background: #EBEBEB;
	max-width: 600px;
    margin: 40px auto;
    border-radius: 10px;
}
#printbtns .printType{
	display: inline-block;
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
}
.sch-logo{
    text-align: center;
}
.studentPrintInfoSubDiv{
    font-family: 'Museo Sans W01_500',arial;
    padding: 10px 0px;
    font-size: 13px;
    letter-spacing: normal;
}
.studentPrintInfoSubDiv h4{
    font-size: 22px;
    margin: 0px;
    padding: 0px 0px 20px 0px;
    text-align: center;
}
.studentPrintInfoSubDiv ol li{
    padding: 0px 0px 20px 0px;
}
.studentPrintInfoCred{
    padding-top: 20px;
    letter-spacing: normal;
}
.studentPrintInfoCredSubDiv{
    display: inline-block;
}
.black-background{
    display: inline-block;
    padding: 5px 12px;
    background-color: #000000 !important;
    border: 2px solid #000000;
    border-width: 2px 0px 2px 2px;
    color: #FFFFFF !important;
    border-radius: 5px 0px 0px 5px;
}
.black-border{
	display: inline-block;
    padding: 5px 12px;
    border: 2px solid #000000;
    border-radius: 0px 5px 5px 0px;
    color: #000000;
    min-width: 150px !important;
}
#printUsernameDiv{
    margin-right: 40px;
}
#printListTable .studentInfoShapeDescription{
    margin: 0px;
}
#printListTable .fa-inverse{
    color: #FFFFFF !important;
}
.qrCodeDiv{
    margin-top: 30px;
}
.classroom-use-printInst-header{
    text-align: center;
    margin-bottom: 20px;
}
.classroom-use-printInst-header h4{
   font-size: 18px;
}
.classroom-use-printInst-header-small-text{
    font-size: 12px;
}
.classroom-use-printInst-content{
    border-top: 2px dashed #6e6e6f;
}
.classroom-use-printInst-student-div-woHeader{
    padding-top: 40px;
}
.classroom-use-printInst-student-div{
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: 20px;
    border: dashed #000;
    border-width: 0px 0px 2px 0px;
}

.classroom-use-printInst-std-name{
    padding: 5px 30px;
    font-size: 18px;
    padding: 5px 12px;
    background-color: #000000 !important;
    color: #FFFFFF !important;
}
.classroom-use-printInst-small-text{
    font-size: 10px;
}
.classroom-use-printInst-url-div{
    font-size: 10px;
    word-break: break-all;
    font-weight: bold;
}
.classroom-use-printInst-el-url-div {
    max-width: 200px;
    
}
.classroom-use-printInst-easyLogin-sub-div{
    padding: 0px 20px;
}
@media not all and (min-resolution:.001dpcm) { @media
{
    .classroom-use-printInst-easyLogin-sub-div {
        padding: 0 15px;
    }
}}
.classroom-use-printInst-easyLogin-sub-div label{
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: normal;
    display: block;
    margin: 10px auto 0 auto;
    max-width: 60px;
}
.classroom-use-printInst-sel-label {
    max-width: 70px !important;
}
.classroom-use-printInst-easyLogin-btn{
    margin-bottom: 0px;
}
.classroom-use-printInst-sub-div{
    padding: 5px 0px;
}
.classroom-use-pintInst-shape-desc{
    font-size: 11px;
    text-align: center;
}
.classroom-use-printInst-cred-sub-div{
    padding: 20px;
}
.classroom-use-printInst-cred-blackBackground{
    padding: 5px 12px;
    border: solid #000000;
    border-width: 2px 2px 0px 2px;
    border-radius: 5px 5px 0px 0px;
    background-color: #000000 !important;
    color: #FFFFFF !important;
    width: 200px;
    margin: auto;
}
.classroom-use-printInst-cred-blackBorder{
    padding: 5px 12px;
    border: 2px solid #000000;
    border-radius: 0px 0px 5px 5px;
    width: 200px;
    margin: auto;
}
.studentInfoShapeDescription.printIconsDesc{
    max-width: none;
}
.classroom-use-printInst-inline-div1 {
    display: inline-block;
    float: left;
    text-align: left;
}
.classroom-use-printInst-inline-div2 {
    display: inline-block;
    float: right;
}
.classroom-use-printInst-info{
    font-size: 10px;
    max-width: 200px;
    margin-bottom: 10px;
}
.classroom-use-printInst-text-div {
    margin: 10px 0;
}
.classroom-use-printInst-qrcode-div {
    float: right;
}
.classroom-use-printInst-easyLogin-div{
    clear: both;
    padding-top: 20px;
}
.easyLoginIconContainer-printInst {
    display: inline-block;
}

.student-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
}

.student-details {
    display: flex;
    flex-direction: column;
}
