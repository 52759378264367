.subscription-dropdown {
    border-radius: 0;
    background: #fff;
    color: #337ab7;
    padding: 0;
    right: 4px;
    left: 0;
}

.subscription-text {

    font-family: 'Museo Sans W01_500',arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #4c357f;
}

.subscription-dropdown li>a.active {
    color:white !important;
}

.subnav-menu-subscriptions-left {
    background: #EBEBEB;
    min-height: 550px;
    width: 23%;
    float: left;
}

.subnav-menu-subscriptions{
    list-style-type: none;
    padding: 10px 0;
    font-family: 'Museo Sans W01_500',arial;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
}
.subnav-menu-subscriptions li{
    font-size: 14px;
    text-align: left;
    padding: 20px 0 1px 10px;

}
.subnav-menu-subscriptions li a{
    color: #5a388a;
    text-decoration: none;
    letter-spacing: 2px;
}
.subnav-menu-subscriptions li a.active{
    text-decoration: underline;
}

@media only screen and (max-width: 767px) {
    .subnav-menu-subscriptions-left {
        width: 23%;
    }
}

@media only screen and (min-width: 768px) {
    .subnav-menu-subscriptions-left {
        width: 23%;
    }
}


@media only screen and (min-width: 991px) {
    .subnav-menu-subscriptions-left {
        width: 18%;
    }
}


@media only screen and (min-width: 1024px) {
    .subnav-menu-subscriptions-left {
        width: 17%;
    }
}

@media only screen and (min-width: 1280px) {
    .subnav-menu-subscriptions-left {
        width: 14%;
    }
}

@media only screen and (min-width: 1366px) {
    .subnav-menu-subscriptions-left {
        width: 14%;
    }
}

@media only screen and (min-width: 1920px) {
    .subnav-menu-subscriptions-left {
        width: 12%;
    }
}

@media only screen and (min-width: 2560px) {
    .subnav-menu-subscriptions-left {
        width: 8%;
    }
}