.modal-header.modal-header-container {
    border: none;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.modal-header-container .modal-title {
    font-family: 'Museo Sans W01_700', arial;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0;
    line-height: normal;
    color: #000000;
}

.modal-footer.modal-buttons-container {
    border: none;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.add-teacher-input-container input {
    border: 1px solid #CCCCCC;
    background: #EBEBEB;
    padding: 5px;
    color: #000000;
    width: 331px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.add-teacher-input-container {
    padding: 5px;
}

.add-teacher-input-container input:focus{
    background: #FFFFFF;
}

.modal-body.modal-body-container.add-teacher-modal-body-container {
    padding: 11px 15px 19px 15px !important;
    border: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.modal-body.modal-body-container .modal-role-alert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100% !important;
}

.error.modal-alert-error {
    font-family: 'Museo Sans W01_500',arial;
    border: none !important;
    width: 331px !important;
    color: #ef7000 !important;
    font-size: 14px !important;
    padding-top: 10px !important;
    line-height: 18px!important;
}

div.add-teacher-modal-instructions {
    margin-bottom: 20px;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #000000;
}

.add-teacher-input {
    height: 40px;
    border-radius: 2px;
}

.btn.stdbtns.modal-add-teacher-button {
    padding: 0 15px 0 15px;
    margin: 0;
    cursor: pointer;
}

.btn.stdbtns.cancelbtn.modal-cancel-button {
    padding: 0 35px 0 35px;
    margin: 0;
}

@media (max-width: 480px) {
    .add-teacher-input-container .add-teacher-input {
        width: 100%;
    }
    .modal-footer.modal-buttons-container {
        border: none;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
    }
    .modal-buttons-container .modal-cancel-button {
        margin-top: 15px !important;
        width: 100%;
    }
    .modal-buttons-container .modal-add-teacher-button {
        width: 100%;
    }
}
