.google-import-modal-dialog-container {
    display: block !important;
    height: auto;
    min-height: 100%;
    position: relative;
}

.error-container {
    width: 95%;
    text-align: left;
    padding-left: 10px;
    padding-top: 30px;
}

.import-classes-confirm-detail-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 13px 11px 13px 11px;
    margin: auto;
    border-bottom: solid 1px #979797;
}

.import-classes-confirm-detail-modal-header-img {
    padding-right: 6px;
}

.import-classes-confirm-detail-modal-title {
    opacity: 0.5;
    font-family: 'Museo Sans W01_500', arial;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
}

.import-classes-confirm-detail-modal-make-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    letter-spacing: 0;
    padding: 0 38px;
}

.import-classes-confirm-detail-modal-make-selection-container * {
    letter-spacing: 0;
}

.import-classes-confirm-detail-modal-make-selection-header-title {
    padding: 5px 0;
}

.import-classes-confirm-detail-modal-make-selection-header-title h3 {
    font-family: 'Museo Sans W01_700', arial;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #333;
    text-align: center;
}

.import-classes-confirm-detail-modal-make-selection-header-description {
    font-family: 'Museo Sans W01_400', arial;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #212121;
    padding-bottom: 20px;
}

.error-class {
    border: 1px solid #ef7000;
}

.duplicate-class-details {
    font-family: 'Museo Sans W01_400', arial;
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    padding-bottom: 20px;
}

.import-classes-confirm-detail-modal-scroll-class table tr {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 51px;
}

.import-classes-confirm-detail-modal-scroll-class {
    overflow-y: auto;
    overflow-x: hidden;
    height: 210px;
}

.import-classes-confirm-detail-modal-courses-container {
    display: block !important;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: normal; /* 1.42857143; */
    color: #333;
    overflow-x: auto;
}

.import-classes-confirm-detail-modal-course-item-container {
    height: 81px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.import-google-school-td-edit.selectize-control .selectize-dropdown,
.import-google-grade-level-td-edit.selectize-control .selectize-dropdown {
    /* relative will hide the drop down under the footer, fixed will show it after an offset equivalent to the overflow rows*/
    position: relative;
    top: auto;
    left: auto;
    width: inherit;
}

.ng-repeat-last td .import-google-grade-level-td-edit.selectize-control .selectize-dropdown {
    /* will allow css styling of the last element of the ng-repeat */
}

.import-classes-confirm-detail-modal-course-item-row:nth-child(odd) {
    background-color: #ebebeb;
}

.import-classes-confirm-detail-modal-course-item-row:nth-child(even) {
    background-color: #fff;
}

.import-classes-confirm-detail-modal-course-item-checkbox-container input[type=checkbox] {
    visibility: hidden;
}

.import-google-class-name-col-header {
    font-family: 'Museo Sans W01_500', arial;
    font-size: 14px;
}

.import-google-school-td-edit {
    outline: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #333;
}

.import-google-school-td-edit .selectize-input {
    height: 40px !important;
    white-space: nowrap !important;
}

.import-google-grade-level-td-edit .selectize-input {
    height: 40px !important;
    white-space: nowrap !important;
    border-radius: 4px !important;
}

.import-google-school-td-edit.error {
    border-radius: 8px;
    height: 42px;
    color: #EC1D25 !important;
    border: 1px solid #EC1D25 !important;
}

.import-google-grade-level-td-edit.error {
    border-radius: 4px;
    height: 42px;
    color: #EC1D25 !important;
    border: 1px solid #EC1D25 !important;
}

.import-google-school-td-edit.validEntry {
    border-radius: 8px;
    height: 42px;
    color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}

.import-google-grade-level-td-edit.validEntry {
    border-radius: 4px;
    height: 42px;
    color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}

.import-google-grade-level-td-edit {
    outline: none;
    word-break: break-word;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #333;
}

.import-google-school-td-no-edit div {
    padding-left: 10px;
}

.import-google-school-td-no-edit {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    color: #333;
}

.import-classes-confirm-detail-modal-scroll-class table tr td {
    height: 51px !important;
}

.import-classes-confirm-detail-modal-course-item-checkbox-container .import-classes-confirm-detail-modal-course-item-checkbox-input-display {
    cursor: pointer;
    position: absolute;
    max-width: unset;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    background: #ffffff;
    border: solid 1.5px #979797;
}

.import-classes-confirm-detail-modal-course-item-checkbox-container {
    width: 16px;
    padding-right: 24px;
    position: relative;
}

.import-classes-confirm-detail-modal-course-item-checkbox-container .import-classes-confirm-detail-modal-course-item-checkbox-input-display:after {
    opacity: 0.02;
    content: '';
    position: absolute;
    width: 9px;
    height: 6px;
    background: transparent;
    top: 2.4px;
    left: 2.2px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;

    transform: rotate(-45deg);
}

.import-classes-confirm-detail-modal-course-item-checkbox-container .import-classes-confirm-detail-modal-course-item-checkbox-input-display:hover::after {
    opacity: 0.3;
}

.import-classes-confirm-detail-modal-course-item-checkbox-container input[type=checkbox]:checked + .import-classes-confirm-detail-modal-course-item-checkbox-input-display:after {
    opacity: 0.3;
}

.import-classes-confirm-detail-modal-course-item-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: unset !important;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal; /* 1.42857143; */
    letter-spacing: 1px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    color: #333;
}

.import-classes-confirm-detail-modal--footer {
    text-align: center;
    padding: 20px;
}

.import-classes-confirm-detail-modal-cancel-button,
.import-classes-confirm-detail-modal-import-button {
    width: 130px;
}

.import-classes-confirm-detail-modal-import-button {
    margin-right: 25px;
    cursor: pointer !important;
}

.import-classes-confirm-detail-modal-courses-container .import-classes-confirm-detail-modal-courses-table {
    width: 100%;
}

.import-classes-confirm-detail-modal-courses-container .import-classes-confirm-detail-modal-courses-table th {
    width: unset;
}

.import-classes-confirm-detail-modal-courses-table th {
    padding-left: 10px;
    padding-bottom: 5px;
}

.import-classes-confirm-detail-modal-courses-table td {
    height: 40px;
    word-break: break-word;
    text-align: left;
    padding: 5px;
}

.import-classes-confirm-detail-class-name-col {
    width: 45% !important;
}

.import-classes-confirm-detail-school-col-name,
.import-classes-confirm-detail-class-name-col-name {
    display: block;
    font-weight: 500;
}

.import-classes-confirm-detail-class-name-col-section {
    display: block;
}

.import-classes-confirm-detail-school-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 32% !important;
}

.import-classes-confirm-detail-grade-level-col {
    width: 23% !important;
}

input.import-google-school-td-edit-disabled {
    width: 100%;
    opacity: 0.5;
    border-radius: 8px;
    height: 40px;
    background-color: white;
}

input.import-google-grade-level-td-edit-disabled {
    width: 100%;
    opacity: 0.5;
    border-radius: 4px;
    height: 40px;
    background-color: white;
}


.import-classes-confirm-detail-modal-scroll-class table {
    width: 100%;
}

@media (min-width: 768px) {
    .import-classes-confirm-detail-modal-courses-container {
        overflow-x: hidden;
    }
}

.import-google-school-td-edit .selectize-input::after {
    right: 5px !important;
}

.import-google-grade-level-td-edit .selectize-input::after {
    right: 5px !important;
}

.import-google-school-td-edit div.selectize-input {
    display: flex;
    align-items: center;
}

.import-google-school-td-edit .selectize-input div.ui-select-match {
    max-width: 98%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.import-google-school-td-edit .selectize-input .ui-select-match span:nth-of-type(2) {
    display: inline-block;
    max-width: 90%;
}

.import-google-school-td-edit .selectize-input input {
    max-width: 90%;
    overflow: hidden;
}

@media (min-width: 768px) {
    .import-google-school-td-edit .selectize-input div.ui-select-match {
        max-width: 98%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .import-google-school-td-edit .selectize-input div.ui-select-match {
        max-width: 123px;
        overflow: hidden;
    }

    .import-google-school-td-edit .selectize-input .ui-select-match span:nth-of-type(2) {
        display: inline-block;
        max-width: 123px;
    }

}
