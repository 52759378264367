@media print
{
    .noPrint
    {
        display: none;
    }
    .onlyPrint {
        display: block;
    }
    .pageBreak{
        page-break-after: always;
    }
    #studentListTable .tdWidth {
        width:25%;
    }
    #printListTable .tdWidth{
        	width: 25%;
    }
    .lineBreak{
        border-top: 2px dashed #737573;
        margin: 0px;
    }
    .colorText{
        color: #0082D6 !important;	
    }
    html,body{
        height: 95%;
    }
    .last > .pageBreak,
    .last > .lineBreak,
    .last > .iterationBreak {
        display: none;
    }
    .last > .lineBreak{
        display: none;
    }
    @page{
        size: auto;
        margin: 3mm;
    }
    .iterationBreak{
        page-break-after: always;
    }
    #maincontainer{
        border: none;
    }
    .modal-dialog{
        display: none !important;
    }
    body{
        overflow: visible !important;
        -webkit-print-color-adjust: exact;
    }
    body.modal-open{
       position: relative !important;
    }
    .modal-content{
        border: none !important;
        overflow: hidden !important;
    }
    .fa-inverse:before {
        color: #fff !important;
    }
    .fa-inverse{
        color: #FFFFFF !important;
    }
    .fa{
        display: inline-block !important;
    }
}